import React, { FC, useEffect, useRef, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { api } from '../api/api'
import Button from '../components/Button'
import Dropdown from '../components/Elements/Dropdown'
import InputFloat from '../components/Elements/InputFloat'
import InputText from '../components/Elements/InputText'
import LoadingSpinner from '../components/LoadingSpinner'
import SelectGame from '../components/Table/SelectGame'
import SelectItemFilters from '../components/Table/SelectItemFilters'
import SelectSalesPeriod from '../components/Table/SelectSalesPeriod'
import SelectService from '../components/Table/SelectService'
import { useActions } from '../hooks/useAction'
import { useTypedSelector } from '../hooks/useTypedSelector'
import DataModel from '../models/dataModel'
import Extensions from '../models/extensions'
import TableExtensions, { TableOptions } from '../models/pageModels/table/tableExtensions'
import { hubConnection } from '../signalR/mainConnection'
import { CurrencyData } from '../types/currency/currency'
import { EOrderBy, EOrderDir, Filter } from '../types/table/filter'
import { EItemStatus, ItemResponse, ItemsChunkResponse } from '../types/table/itemResponse'
import { ItemSale, ItemSaleLast } from '../types/table/itemSale'
import { RequestData, ServicesFee } from '../types/table/requestData'
import { EPriceType, EServices, TableHelper } from '../types/tableHelper'
import { ESubscription } from '../types/user/user'
import { List, WindowScroller } from 'react-virtualized'
import AutoSizer from 'react-virtualized-auto-sizer'
import styles from './css/TablePage.module.css'
import PriceCheck from '../components/Table/PriceCheck'
import { Profile, ProfileCreateResponse, ProfileWithJson, ProfileWithJsonResponse } from '../types/table/profile'
import InputWindow from '../components/Table/InputWindow'
import ListsWindow from '../components/Table/ListsWindow'
import Toast from '../components/Elements/Toast/toast'
import FilterCheck from '../components/Table/FilterCheck'
import Notification from '../models/notification'
import { defaultTypes } from '../models/tableFilterModel'
import TableSelectedFilter from '../components/Table/TableSelectedFilter'
import { EResponseExceptionStatus } from '../types/Enum/EResponseExceptionStatus'
import { Apps } from '../apps'

const dataFavoriteAttr = 'data-favorite'

let page = 1
let updatedTime1 = 0
let updatedTime2 = 0
let options: TableOptions = new TableOptions(20)
let inited = false
let currentProfile: ProfileWithJson = null
let profileChanging = false
let blackListAdding = false
let eventAdded = false
let eventCount = 0

const TablePage: FC = () => {
	const {
		user,
		activeProfile,
		priceLimit,
		feed: { freeServices: _freeServices, disabledServices: _disabledServices },
	} = useTypedSelector(x => x.user)
	const freeServices = new Set(_freeServices)
	const disabledServices = new Set(_disabledServices)
	const { filter, loaded: filterLoaded } = useTypedSelector(x => x.table)
	const { loaded: feesLoaded, data: fees } = useTypedSelector(x => x.fees)
	const {
		lang: { Value: lang },
		current: currLang,
	} = useTypedSelector(x => x.lang)
	const { loadingScreen: loading } = useTypedSelector(x => x.loading)

	const { loadTableFilter, setLoadingScreen, setFees, setTableFilter, showConfirmation, showCheckItem, setActiveProfile } = useActions()

	const itemsRef = useRef<HTMLDivElement[]>([])

	// table
	const [next, setNext] = useState(true)
	const [items, setItems] = useState<ItemResponse[]>(null)
	const [tableLoading, setTableLoading] = useState(true)
	const [profileList, setProfileList] = useState<Profile[]>()
	const [profileNewActive, setProfileNewActive] = useState(false)
	const [listsActive, setListsActive] = useState(false)
	const [selectedItem, setSelectedItem] = useState(-1)

	const [indexForChangePrice, setIndexForChangePrice] = useState(0)
	const [priceChangeIsFirst, setPriceChangeIsFirst] = useState(false)
	const [priceUpdateActive, setPriceUpdateActive] = useState(false)

	const [updateCount, setUpdateCount] = useState(0)
	const [resetFilterCount, setResetFilterCount] = useState(0)

	const [zoomLevel, setZoomLevel] = useState(1)

	const getProfileList = async (): Promise<Profile[]> => {
		try {
			const response = await api.get<Profile[]>('table/profiles')
			const data = response.data

			return data
		} catch {}
		return null
	}

	const resetFilter = () => {
		filter.allTypes = defaultTypes()
		
		filter.appId = Apps.CsGo
		filter.countMin1 = 1
		filter.countMin2 = 0
		filter.countMax1 = 0
		filter.countMax2 = 0
		filter.service1 = 0
		filter.service2 = 0
		filter.minSales = 0
		filter.direction = 0
		filter.order = 0
		filter.priceMax1 = 0
		filter.priceMax2 = 0
		filter.priceMin1 = 0
		filter.priceMin2 = 0
		filter.priceType1 = 0
		filter.priceType2 = 0
		filter.profitMax = 0
		filter.profitMin = 0
		filter.salesPeriod = 0
		filter.salesService = 0
		filter.searchName = ''
		filter.liquidityMin = 0
		filter.liquidityMax = 100

		filter.autoReset = false
		filter.inInventory = false
		filter.withSound = false;

		filter.filterSales = null;

		// saveTableTypes(filter, activeProfile)
		setTableFilter(filter, true, true)
		setResetFilterCount(resetFilterCount + 1)
		reset()
	}

	const getProfile = async (id: number = null): Promise<ProfileWithJson> => {
		try {
			const response = await api.get<ProfileWithJsonResponse>(`table/profile/${id ?? activeProfile}`)
			const data = response.data

			if (data?.success) {
				return data.data
			}
		} catch {}
		return null
	}

	const createProfile = async (profile: ProfileWithJson): Promise<ProfileCreateResponse> => {
		const response = await api.post<ProfileCreateResponse>('table/profile/create', profile)

		return response.data
	}

	const addProfileAddNewIfAvailable = (profileList: Profile[]) => {
		if (!profileList) {
			return
		}

		const newAvailable =
			{
				[ESubscription.BaseSubscription]: profileList.length < 5,
				[ESubscription.ProSubscription]: profileList.length < 20,
			}[user.access] || false

		if (newAvailable) {
			profileList.push({ id: -1 })
		}
	}

	useEffect(() => {
		document.title = 'vvTable - Price comparison for CS2, Dota 2, Tf2 and Rust'

		inited = false

		setLoadingScreen(true)

		if (!feesLoaded) {
			DataModel.LoadAllFees(user)
			setFees(DataModel.Fees)
		}

		if (!filterLoaded) {
			const getProfileApi = async () => {
				if (activeProfile > 0) {
					currentProfile = await getProfile()
				} else {
					const newProfile: ProfileWithJson = {} as ProfileWithJson
					const success = await createProfile(newProfile)
					if (success?.success) {
						setActiveProfile(success.id)
						currentProfile = await getProfile(success.id)
					} else {
						newProfile.name = 'Noname'
						currentProfile = newProfile
					}
				}

				DataModel.Currency.SetCurrency(currentProfile.currency)

				loadTableFilter(user, currentProfile, freeServices, disabledServices)
				
			}
			
			getProfileApi()
		}

		const loadProfileListApi = async () => {
			const newProfileList = await getProfileList()

			addProfileAddNewIfAvailable(newProfileList)

			setProfileList(newProfileList)
		}

		loadProfileListApi()

		if (!eventAdded){
			window.addEventListener('items_updated', () => {
				eventCount += 1
				setUpdateCount(eventCount)
			})
			eventAdded = true
		}
	}, [])

	useEffect(() => {
		if (!inited && feesLoaded && filterLoaded) {
			initItems()
			inited = true
		
			getItemsFull(true)

			setLoadingScreen(false)
		}
	}, [feesLoaded, filterLoaded])

	useEffect(() => {
		if (profileChanging) {
			profileChanging = false
			initItems()

			const fetchApi = async () => {
				await getItemsFull(true)

				setLoadingScreen(false)
			}

			fetchApi()
		}
	}, [filter])

	useEffect(() => {
		if (blackListAdding) {
			blackListAdding = false

			itemsRef.current.forEach((x, i) => {
				const item = items[i]
				x?.setAttribute(dataFavoriteAttr, item.f ? '1' : '0')
			})
		}
	}, [items])

	useEffect(() => {
		if(updateCount)	
		{
			if(filter.autoReset)
			{
				reset()
			}

			new Toast(null, lang.TablePage_Toast_ItemsWasUpdated, 'custom', true, 7_000, filter.withSound)
		}
	}, [updateCount])

	useEffect(() => {
		setResetFilterCount(resetFilterCount + 1)
	}, [activeProfile])

	const getCurrentZoom = (): number => {
		const body = document.body;
		
		const zoom = window.getComputedStyle(body).getPropertyValue('zoom');
		
		return zoom ? parseFloat(zoom) : 1; 
	};

	useEffect(() => {
		const updateZoomLevel = () => {
		const currentZoom = getCurrentZoom(); 
		setZoomLevel(currentZoom);
		};
	  
		updateZoomLevel();
	  
		window.addEventListener("resize", updateZoomLevel);
	  
		return () => window.removeEventListener("resize", updateZoomLevel);
	  }, []);

	if (!filter) {
		return
	}

	const getItemsChunk = async () => {
		if (!next && page > 1) return

		const types: Object = {}

		filter.allTypes.get(0).Types.forEach(allTypes => {
			const values = allTypes

			values.forEach((type, key) => {
				types[key] = type.TypeValue
			})
		})

		filter.allTypes.get(filter.appId).Types.forEach(allTypes => {
			const values = allTypes

			values.forEach((type, key) => {
				types[key] = type.TypeValue
			})
		})

		const requestData = new RequestData()
		requestData.currency = DataModel.Currency.Current
		requestData.filter = { ...filter, types: types, allTypes: undefined }
		requestData.fee1 = fees[filter.appId][filter.service1]
		requestData.fee2 = fees[filter.appId][filter.service2]
		requestData.profileId = currentProfile.id;

		try {
			const response = await api.post<ItemsChunkResponse>('table/items-chunk', requestData, { params: { page } })
			
			const data = response.data

			setNext(data.next)
			updatedTime1 = data.updatedTime1
			updatedTime2 = data.updatedTime2

			if (page > 1) {
				setItems(items.concat(...data.items))
			} 
			else {
				setItems(data.items)
			}
		} catch (error) {
			console.log(error)
			if (error?.response?.status === 429) {
				Notification.ShowNotification(lang.TablePage_Notification_TooManyRequests, 5, false)
			}
			else if (error?.response?.data?.status == EResponseExceptionStatus.InventoryClosedError)
            {
                Notification.ShowNotification(lang.Notification_InventoryClosedError)
            }			
		}

		updateAnim()
	}

	const getItemsFull = async (force = false) => {
		if (tableLoading && !force) return

		setTableLoading(true)

		try {
			await getItemsChunk()
		} catch (err) {
			console.error(err)
		}

		setTableLoading(false)
	}

	const gameChanged = (appId: number) => {
		if (filter.appId === appId) return

		filter.appId = appId

		if (!TableHelper.CheckServiceByAppId(filter.service1, appId)) {
			filter.service1 = EServices.Steam
			filter.priceType1 = EPriceType.Normal
		}

		if (!TableHelper.CheckServiceByAppId(filter.service2, appId)) {
			filter.service2 = EServices.Steam
			filter.priceType2 = EPriceType.Normal
		}

		if (!TableHelper.CheckServiceByAppId(filter.salesService, appId)) {
			filter.salesService = EServices.Steam
		}

		if(appId !== Apps.CsGo)
		{
			filter.liquidityMin = 0
			filter.liquidityMax = 100
		}

		filter.searchName = ''
		setTableFilter(filter, true)

		reset()
	}

	const swapServices = async () => {
		const temp = filter.service1
		filter.service1 = filter.service2
		filter.service2 = temp

		const temp1 = filter.priceType1
		filter.priceType1 = filter.priceType2
		filter.priceType2 = temp1

		setTableFilter(filter, true)

		await updateItems()
	}

	const checkActive = (id: number) => {
		showCheckItem(id)
	}

	const updateItems = async () => {
		if (!filter || loading) return

		setTableLoading(true)

		initItems()	
		await getItemsChunk()

		setTableLoading(false)
	}

	const sort = async (order: EOrderBy, defDir: EOrderDir) => {
		const otherDir = defDir === EOrderDir.Asc ? EOrderDir.Desc : EOrderDir.Asc

		if (filter.order === order) {
			if (filter.direction === otherDir) {
				filter.order = EOrderBy.None
			} else if (filter.direction === defDir) {
				filter.direction = otherDir
			} else {
				filter.direction = defDir
			}
		} else {
			filter.order = order
			filter.direction = defDir
		}

		setTableFilter(filter, true)

		await updateItems()
	}

	const getTimeFull = (unixSec: number): string => {
		return TableExtensions.GetTimeFull(unixSec)
	}

	const addItemToBlackList = async (app: number, id: number) => {
		await hubConnection.addBlacklist(currentProfile.id, app, filter.service1, filter.service2, id)

		blackListAdding = true
		const newItems = items.filter(x => x.i != id)
		setItems(newItems)
	}

	const addItemToBlackListTmp = async (app: number, id: number) => {
		await hubConnection.addBlacklistTmp(currentProfile.id, app, filter.service1, filter.service2, id)

		blackListAdding = true
		const newItems = items.filter(x => x.i != id)
		setItems(newItems)
	}

	const addItemToFavorites = async (app: number, id: number) => {
		await hubConnection.addFavorite(currentProfile.id, app, filter.service1, filter.service2, id)
	}

	const removeItemFromFavorites = async (app: number, id: number) => {
		await hubConnection.removeFavorite(currentProfile.id, app, filter.service1, filter.service2, id)
	}

	const updateAutoResetFilter = (autoReset: boolean) => {
		filter.autoReset = autoReset
		setTableFilter(filter, true)
	}

	const updateWithSound = (withSound: boolean) => {
		filter.withSound = withSound
		setTableFilter(filter, true)
	}

	const reset = () => {
		if (filter) {
			filter.searchName = ''
			setTableFilter(filter, false)
		}

		initItems()

		getItemsFull()
	}

	const clearAllItems = () => clearItems()

	const clearItems = () => setItems(null)

	const initItems = () => {
		setNext(true)
		page = 1
		updatedTime1 = updatedTime2 = 0

		setItems(null)
		setSelectedItem(-1)
	}

	const updateAnim = () => {
		window.tableJs.updateAnim()
	}

	// From table.cs

	const currencyChange = async (value: string) => {
		DataModel.Currency.SetCurrency(value)

		await updateItems()

		api.post('table/profile/update', { id: activeProfile, currency: value })
	}

	const selectProfileFromList = async (id: number) => {
		if (id === -1) {
			setProfileNewActive(true)
			return
		}

		profileChanging = true
		setLoadingScreen(true)

		const profile = await getProfile(id)

		if (!profile) {
			setLoadingScreen(false)
			return
		}

		setActiveProfile(profile.id)
		currentProfile = profile

		DataModel.Currency.SetCurrency(profile.currency)
		loadTableFilter(user, profile, freeServices, disabledServices)
	}

	const createNewProfile = async (name: string) => {
		const filtersJson = JSON.stringify({ ...filter, searchName: undefined, allTypes: undefined })
		const typesJson = JSON.stringify(Filter.GetTypesObject(filter))

		const newProfile = {
			name: name,
			currency: DataModel.Currency.Current,
			filtersJson: filtersJson,
			typesJson: typesJson,
		} as ProfileWithJson

		const response = await createProfile(newProfile)

		if (response.success) {
			newProfile.id = response.id

			const newProfileList = profileList.filter(x => x.id !== -1)
			newProfileList.push(newProfile)
			newProfileList.push({ id: -1 })

			setProfileList(newProfileList)
			setActiveProfile(newProfile.id)
			currentProfile = newProfile
		}
	}

	const saveChangedPrice = async (priceStr: string) => {
		const item = items[indexForChangePrice];

		const price = priceChangeIsFirst 
			? item.i1 
			: item.i2

		const newValue = Number.parseFloat(priceStr?.replaceAll(',', '.'))

		if (!newValue || newValue === price.p) {
			return;
		}
		
		price.p = Number(newValue.toFixed(2));

		const fee = fees[filter.appId][filter.service2] as ServicesFee
		
		let resultPercent = filter.priceType2 == EPriceType.Deposit
		? (item.i2.p * 100) / (item.i1.p) - 100
		: (item.i2.p * (100 - fee.fee)) / (item.i1.p * (1 - fee.bonus / 100)) - 100;

		item.p = Number(resultPercent.toFixed(2))
	}

	const handleDoubleClickPriceFirst = async (index: number) =>  {
		setPriceChangeIsFirst(true)
		setIndexForChangePrice(index)
		setPriceUpdateActive(true)
	}
  
	const handleDoubleClickPriceSecond = async (index: number) =>  {
		setPriceChangeIsFirst(false)
		setIndexForChangePrice(index)
		setPriceUpdateActive(true)
	}

	const currencies = DataModel.Currency.CurrencySet()

	if (!filter || !fees) return

 

	return (
		<div>
			<InputWindow
				active={profileNewActive}
				buttonText={lang.TablePage_ProfileListAdd}
				startValue={lang.TablePage_ProfileStartValue}
				onSave={name => {
					createNewProfile(name)
				}}
				onClose={() => setProfileNewActive(false)}
			/>
      		<InputWindow
				active={priceUpdateActive}
				buttonText={lang.TablePage_ConfirmChangePrice}
				startValue={items ? priceChangeIsFirst ? items[indexForChangePrice]?.i1?.p.toString() : items[indexForChangePrice]?.i2?.p.toString() : ""}
				onSave={price => {
					saveChangedPrice(price)
				}}
				onClose={() => setPriceUpdateActive(false)}
			/>
			<ListsWindow
				active={listsActive}
				onClose={async needRefresh => {
					setListsActive(false)

					if (needRefresh) {
						await updateItems()
					}
				}}
				onNewProfiles={newProfileList => {
					const active = newProfileList.find(x => x.id === activeProfile)
					currentProfile.name = active.name

					addProfileAddNewIfAvailable(newProfileList)
					setProfileList(newProfileList)
				}}
			/>
			<div id='filter-content' className={styles.filterContent} key={resetFilterCount}>
				{user.access === ESubscription.NoSubscription && (
					<React.Fragment>
						{{
							0: (
								<div className={styles.subscriptionAlert}>
									{ user.subPaused ? 'Ваша подписка приостановлена' : 'У вас нет подписки' }. Ваша максимальная цена ${user.nickBonus ? priceLimit.bonus : priceLimit.value} 
									{ !user.subPaused ? <React.Fragment>(Купите{' '} <NavLink to='/sub'>подписку</NavLink> чтобы снять это ограничение)</React.Fragment> : undefined }
									<br />
									{!user.nickBonus ? (
										<React.Fragment>
											<a href={`https://steamcommunity.com/profiles/${user.steamID}/edit/info`} target='_blank' rel='noreferrer nofollow'>
												Добавьте 'tablevv.com' в ник
											</a>{' '}
											, чтобы увеличить макс. цену до ${priceLimit.bonus} (После изменения необходимо заново авторизоваться)
										</React.Fragment>
									) : undefined}
								</div>
							),
						}[currLang] || (
							<div className={styles.subscriptionAlert}>
								{ user.subPaused ? 'You subscription paused' : `You don't have a subscription` }. Your maximum price is ${user.nickBonus ? priceLimit.bonus : priceLimit.value}
								{ !user.subPaused ? <React.Fragment>(Buy a{' '} <NavLink to='/sub'>subscription</NavLink> to remove the restriction)</React.Fragment> : undefined }
								<br />
								{!user.nickBonus ? (
									<React.Fragment>
										<a href={`https://steamcommunity.com/profiles/${user.steamID}/edit/info`} target='_blank' rel='noreferrer nofollow'>
											Add 'tablevv.com' to nick
										</a>{' '}
										to increase the max price to ${priceLimit.bonus} (After changing, you need to log in again)
									</React.Fragment>
								) : undefined}
							</div>
						)}
					</React.Fragment>
				)}
				<div className={styles.topFilters}>
					<div className='elems-10'>
						{user.access > ESubscription.NoSubscription && (
							<Dropdown title={`${lang.TablePage_ProfileList}:`}>
								<div className={`${styles.title} ${styles.profileTitle} title`}>{currentProfile?.name ?? 'Noname'}</div>
								<div className='menu'>
									{profileList?.map((profile, i) => {
										if (profile.id == currentProfile.id) {
											return
										}

										let classAddNew = ''

										if (profile.id === -1) {
											profile.name = lang.TablePage_ProfileListAdd
											classAddNew = ` ${styles.addNew}`
										}

										return (
											<div
												className={`${styles.menuItem} ${styles.profileItem} menu-item${classAddNew}`}
												onClick={() => selectProfileFromList(profile.id)}
												key={i}
											>
												{profile?.name ?? profile.id}
											</div>
										)
									})}
								</div>
							</Dropdown>
						)}
						<SelectGame onChanged={gameChanged} app={filter.appId} />
						<SelectItemFilters onChanged={null} afterChange={null} />
						<Dropdown title={`${lang.TablePage_Currency}:`}>
							<div className={`${styles.title} title`}>{DataModel.Currency.Current}</div>
							<div className='menu'>
								{Array.from(currencies).map((v, i) => {
									const code = v[0]
									const currency = v[1]

									if (code === DataModel.Currency.Current) return

									return (
										<div
											key={i}
											className={`${styles.menuItem} ${styles.currencyItem} menu-item`}
											onClick={() => currencyChange(code)}
											title={Extensions.Round(currency.Value).toString()}
										>
											<span className={styles.currencyIcon}>{currency.Symbol}</span>
											{code}
										</div>
									)
								})}
							</div>
						</Dropdown>
						<Button text={lang.TablePage_Lists} onClick={() => setListsActive(true)} />
						<Button text={lang.TablePage_ResetFilters} onClick={() => showConfirmation({
							text: lang.Confirmation_ResetFilters,
							action: e => {
								if (e) {
									resetFilter()
									Notification.ShowNotification(lang.Notification_Success)
								}	
							},
						})} />
						{/* <Button text={lang.TablePage_Clear} iconAwesomeClass='fas fa-star fa-lg' onClick={clearFavoritesWithConfirm} /> */}
						{/* <Button text={lang.TablePage_Clear} iconAwesomeClass='fas fa-ban fa-lg' onClick={clearBlackListWithConfirm} /> */}
					</div>
					<SelectSalesPeriod onChanged={reset} />
				</div>
				<div className={styles.servicesFilters} style={{ marginTop: 50 }}>
					<div>
						<SelectService isSecond={false} onChanged={reset} onNeedClear={clearItems} onNeedReset={reset} />
					</div>
					<div className={`${styles.servicesSwapBtn} btn-hov`} onClick={swapServices}>
						<i className='fas fa-exchange-alt'></i>
					</div>
					<div>
						<SelectService isSecond={true} onChanged={reset} onNeedClear={clearItems} onNeedReset={reset} />
					</div>
				</div>
				<div className={styles.filtersFooter}>
					<div>
						{filter && !loading && (
							<>
								<div>
									{lang.TablePage_LastUpdate_Title} 1: {!tableLoading && <span style={{ fontWeight: 400 }}>{getTimeFull(updatedTime1)}</span>}
								</div>
								<div>
									{lang.TablePage_LastUpdate_Title} 2: {!tableLoading && <span style={{ fontWeight: 400 }}>{getTimeFull(updatedTime2)}</span>}
								</div>
							</>
						)}
					</div>
					<div style={{ display: 'flex', alignItems: 'flex-end' }}>
						{filter && (
							<React.Fragment>
								<div style={{ marginRight: 15 }}>
									<div>{lang.TablePage_MinProfit}: </div>
									<InputFloat
										className={`${styles.inputStyle} ${styles.profit} input-style text-left`}
										defaultValue={filter.profitMin}
										onValueChange={value => {
											filter.profitMin = value
											setTableFilter(filter, true)
										}}
									/>
								</div>
								<div style={{ marginRight: 15 }}>
									<div>{lang.TablePage_MaxProfit}: </div>
									<InputFloat
										className={`${styles.inputStyle} ${styles.profit} input-style text-left`}
										defaultValue={filter.profitMax}
										onValueChange={value => {
											filter.profitMax = value
											setTableFilter(filter, true)
										}}
									/>
								</div>
							</React.Fragment>
						)}
						<TableSelectedFilter/>
						<div className={`${styles.tableBtn} ${filter.autoReset ? undefined : 'btn-hov'}`} style={{ marginLeft: 30 }} onClick={() => {
							if(!filter.autoReset){
								reset()
							}
							}}>
							{lang.TablePage_ButtonUpdate}
						</div>
					</div>
				</div>
			</div>
			{items && ( 
				<div id='table-content' className={styles.tableContent}>
					<div id='main-table' className={styles.mainTable}>
						<div className={styles.head}>
							<div className={`${filter.appId !== Apps.CsGo ? styles.tableName : styles.tableNameWithL} h-100`}>
								{lang.TablePage_Table_SkinName}
								<br />
								<InputText
									placeholder={`${lang.TablePage_Table_Search}...`}
									className={styles.searchName}
									defaultText={filter.searchName}
									onValueChange={value => {
										if (filter.searchName === value) {
											return
										}
										filter.searchName = value
										setTableFilter(filter, false)
										updateItems()
									}}
								/>
							</div>
							<div className={filter.appId !== Apps.CsGo ? styles.tableTools : styles.tableToolsWithL}>{lang.TablePage_Table_Tools}</div>
							<div className={filter.appId !== Apps.CsGo ? styles.tableSales : styles.tableSalesWithL}>
								<div
									className={`${styles.sortHeader} ${
										filter.order === EOrderBy.Sales ? (filter.direction === EOrderDir.Asc ? styles.asc : styles.desc) : ''
									}`}
									onClick={() => sort(EOrderBy.Sales, EOrderDir.Desc)}
								>
									{lang.TablePage_Table_Sales}
								</div>
								<div className={styles.headService}>{TableHelper.GetServiceName(filter.salesService)}</div>
							</div>
							{filter.appId === Apps.CsGo 
								? <div className={styles.tableLiquidity}>
										<div 
											className={`${styles.sortHeader} ${
											filter.order === EOrderBy.Liquidity ? (filter.direction === EOrderDir.Asc ? styles.asc : styles.desc) : ''
											}`} 
											onClick={() => sort(EOrderBy.Liquidity, EOrderDir.Desc)}>
											{lang.TablePage_Liquidity}
										</div>
									</div>
							 	: <></>}
							
              <div className={filter.appId !== Apps.CsGo ? styles.tablePrice : styles.tablePriceWithL}>
                <div
                  className={`${styles.sortHeader} ${
                    filter.order === EOrderBy.Price1 ? (filter.direction === EOrderDir.Asc ? styles.asc : styles.desc) : ''
                  }`}
                  onClick={() => sort(EOrderBy.Price1, EOrderDir.Desc)}
                >
                  {lang.TablePage_Table_Price}
                </div>
                <div className={styles.headService}>{TableHelper.GetServiceName(filter.service1)}</div>
              </div>
							<div className={filter.appId !== Apps.CsGo ? styles.tablePrice : styles.tablePriceWithL}>
								<div
									className={`${styles.sortHeader} ${
										filter.order === EOrderBy.Price2 ? (filter.direction === EOrderDir.Asc ? styles.asc : styles.desc) : ''
									}`}
									onClick={() => sort(EOrderBy.Price2, EOrderDir.Desc)}
								>
									{lang.TablePage_Table_Price}
								</div>
								<div className={styles.headService}>{TableHelper.GetServiceName(filter.service2)}</div>
							</div>
							<div className={filter.appId !== Apps.CsGo ? styles.tableProfit : styles.tableProfitWithL}>
								<div
									className={`${styles.sortHeader} ${
										filter.order === EOrderBy.Profit ? (filter.direction === EOrderDir.Asc ? styles.asc : styles.desc) : ''
									}`}
									onClick={() => sort(EOrderBy.Profit, EOrderDir.Desc)}
								>
									{lang.TablePage_Table_Profit}
								</div>
							</div>
							<div className={filter.appId !== Apps.CsGo ? styles.tableCount : styles.tableCountWithL}>
								<div
									className={`${styles.sortHeader} ${
										filter.order === EOrderBy.Count1 ? (filter.direction === EOrderDir.Asc ? styles.asc : styles.desc) : ''
									}`}
									onClick={() => sort(EOrderBy.Count1, EOrderDir.Desc)}
								>
									{lang.TablePage_Table_Count}
								</div>
								<div className={styles.headService}>{TableHelper.GetServiceName(filter.service1)}</div>
							</div>
							<div className={filter.appId !== Apps.CsGo ? styles.tableCount : styles.tableCountWithL}>
								<div
									className={`${styles.sortHeader} ${
										filter.order === EOrderBy.Count2 ? (filter.direction === EOrderDir.Asc ? styles.asc : styles.desc) : ''
									}`}
									onClick={() => sort(EOrderBy.Count2, EOrderDir.Desc)}
								>
									{lang.TablePage_Table_Count}
								</div>
								<div className={styles.headService}>{TableHelper.GetServiceName(filter.service2)}</div>
							</div>
						</div>
					</div>
					{(() => {
						const fee = fees[filter.appId][filter.service2] as ServicesFee
						const c1 = CurrencyData.GetCurrency(filter.service1)
						const c2 = CurrencyData.GetCurrency(filter.service2)
						const cSales = CurrencyData.GetCurrency(filter.salesService)

						if (!fee || !items) {
							return
						}

						return (
							<div>
								<WindowScroller>
									{({ height, isScrolling, registerChild, scrollTop }) => {
										return (
											<AutoSizer disableHeight>
												{({ width }) => {
													return (
														<div ref={registerChild}>
															<List
																autoHeight
																height={height / zoomLevel}
                                								isScrolling={isScrolling}
                                								rowCount={items.length}
																rowHeight={68}
																scrollTop={ scrollTop / zoomLevel}
																width={width}
																overscanRowCount={5}
																rowRenderer={({ key: _key, index: i, style }) => {
																	const item = items[i]

																	const id = i

																	const name = item.n

																	const isFavorites = !!item.f

																	let sales: ItemSale = item.s.d
																	let lastSales: ItemSaleLast[] = item.s.l
																	let totalSales = item.s.t

																	return (
																		<div
																			id={id}
																			key={_key}
																			data-favorite={isFavorites ? '1' : '0'}
																			ref={ref => (itemsRef.current[i] = ref)}
																			className={styles.listItem}
																			style={style}
																			data-selected={item.i === selectedItem ? '' : undefined}
																			onClick={() => {
																				setSelectedItem(item.i)
																			}}
																		>
																			<div 
																				className={`${styles.tdName} ${filter.appId !== Apps.CsGo ? styles.tableName : styles.tableNameWithL} h-100`}
																				onClick={() => window.blaz.CopyToClipboard(name)}
																			>
																				<div className={`${styles.flex} flex h-100`}>
																					<div
																						className={styles.nameContent}
																						title={lang.TablePage_Title_Copy}
																					>
																						{name}
																					</div>
																				</div>
																				{item.i1.w > 0 &&
																					filter.priceType1 !== EPriceType.Order &&
																					filter.priceType1 !== EPriceType.NoHold && (
																						<div className={styles.wtime}>{item.i1.w} h.</div>
																					)}
																			</div>
																			<div className={`${styles.tdTools} ${filter.appId !== Apps.CsGo ? styles.tableTools : styles.tableToolsWithL}`}>
																				<React.Fragment>
																					<div
																						className={styles.toolItem}
																						onClick={e => {
																							const target = e.currentTarget
																							const isActive =
																								itemsRef.current[i].getAttribute(dataFavoriteAttr) === '1'
																							if (isActive) {
																								removeItemFromFavorites(filter.appId, item.i)
																								item.f = false
																								itemsRef.current[i].setAttribute(dataFavoriteAttr, '0')
																								target.title = lang.TablePage_Title_AddToFavorites
																							} else {
																								addItemToFavorites(filter.appId, item.i)
																								item.f = true
																								itemsRef.current[i].setAttribute(dataFavoriteAttr, '1')
																								target.title = lang.TablePage_Title_RemoveFromFavorites
																							}
																						}}
																						title={
																							item.f
																								? lang.TablePage_Title_RemoveFromFavorites
																								: lang.TablePage_Title_AddToFavorites
																						}
																					>
																						<i className={`${styles.favoriteIcon} fa fa-star fa-lg`} />
																					</div>
																					<div
																						className={styles.toolItem}
																						onClick={() => addItemToBlackList(filter.appId, item.i)}
																						title={lang.TablePage_Title_AddToBlacklist}
																					>
																						<i className='fas fa-ban fa-lg'></i>
																					</div>
																					<div
																						className={styles.toolItem}
																						onClick={() => addItemToBlackListTmp(filter.appId, item.i)}
																						title={lang.TablePage_Title_AddToBlacklistTmp}
																					>
																						<i className='far fa-clock fa-lg'></i>
																					</div>
																					{user.access === ESubscription.ProSubscription && (
																						<div
																							className={styles.toolItem}
																							onClick={() => checkActive(item.i)}
																							title={lang.TablePage_Title_PriceCheck}
																						>
																							<i className='fas fa-dollar-sign fa-lg'></i>
																						</div>
																					)}
																				</React.Fragment>
																			</div>
																			<div className={`${styles.tdSales} ${filter.appId !== Apps.CsGo ? styles.tableSales : styles.tableSalesWithL} ${styles.linkGray}`}>
																				<div className={`${styles.flex} flex`}>
																					<a
																						className={`${styles.flex} flex`}
																						onMouseOver={() => {
																							const rootElem = document.getElementById(id.toString())
																							const divSales = rootElem.querySelector('.' + styles.salesInfo)
																							divSales.classList.remove(styles.down)
																							if (
																								divSales.getBoundingClientRect().y -
																									divSales.getBoundingClientRect().height <
																								-100
																							)
																								divSales.classList.add(styles.down)
																							divSales.classList.add(styles.saleShow)
																						}}
																						onMouseOut={() => {
																							const rootElem = document.getElementById(id.toString())
																							const divSales = rootElem.querySelector('.' + styles.salesInfo)
																							divSales.classList.remove(styles.saleShow)
																						}}
																						href={Extensions.GetItemLink(name, filter.appId, filter.salesService)}
																						rel='noreferrer nofollow'
																						target='_blank'
																					>
																						<div className={styles.count}>{totalSales}</div>
																						<img
																							src={`Resources/ServicesIcons/${EServices[
																								filter.salesService
																							].toLowerCase()}.png`}
																							alt='service'
																						/>
																					</a>
																					<div className={styles.salesInfo}>
																						<div className={styles.header}>
																							{lang.TablePage_Sales_Total}: {totalSales}
																						</div>
																						<React.Fragment>
																							<div className={styles.salesDesc}>
																								<div className={styles.singleSaleInfo}>
																									{lang.TablePage_Sales_Max}
																									<div>
																										{sales?.h
																											? DataModel.Currency.GetCurrencyTextByService(
																													sales.h,
																													filter.salesService
																											  )
																											: '-'}
																									</div>
																								</div>
																								<div className={styles.singleSaleInfo}>
																									{lang.TablePage_Sales_Min}
																									<div>
																										{sales?.l
																											? DataModel.Currency.GetCurrencyTextByService(
																													sales.l,
																													filter.salesService
																											  )
																											: '-'}
																									</div>
																								</div>
																								<div className={styles.singleSaleInfo}>
																									{lang.TablePage_Sales_Avg}
																									<div>
																										{sales?.a
																											? DataModel.Currency.GetCurrencyTextByService(
																													sales.a,
																													filter.salesService
																											  )
																											: '-'}
																									</div>
																								</div>
																								<div className={styles.singleSaleInfo}>
																									{lang.TablePage_Sales_Corridor}
																									<div>
																										{sales?.c
																											? DataModel.Currency.GetCurrencyTextByService(
																													sales.c,
																													filter.salesService
																											  )
																											: '-'}
																									</div>
																								</div>
																							</div>
																							<div className={styles.header}>{lang.TablePage_Sales_Last}:</div>
																							<div className={styles.salesLast}>
																								{lastSales?.map((sale, i) => {
																									return (
																										<div className={styles.salesLastItem} key={i}>
																											<div>{TableExtensions.GetTime(sale.t)}</div>
																											<div className='ml-3'>
																												{sale.c > 1 ? <>{sale.c} x </> : <></>}

																												{DataModel.Currency.GetCurrencyTextByService(
																													sale.p,
																													filter.salesService
																												)}
																												{DataModel.Currency.Current !== cSales && (
																													<span
																														className={`${styles.salesLastSource} ml-1`}
																													>
																														{DataModel.Currency.GetSymbol(
																															sale.p,
																															cSales
																														)}
																													</span>
																												)}
																											</div>
																										</div>
																									)
																								})}
																							</div>
																						</React.Fragment>
																					</div>
																				</div>
																			</div>
																			{filter.appId === Apps.CsGo ? <div className={`${styles.tdLiquidity} ${styles.tableLiquidity} `}>
																				{item.l !== undefined && item.l !== null && item.l !== 0
																					? item.l + " %"
																					: 0 + " %"}
																			</div> : <></>}
																			
																			<div className={`${styles.tdPrice} ${filter.appId !== Apps.CsGo ? styles.tablePrice : styles.tablePriceWithL}`}
                                        										onDoubleClick={() =>
																				 	handleDoubleClickPriceFirst(i)
																				}>
																				{DataModel.Currency.GetSymbol(item.i1.p)}
																				{DataModel.Currency.Current !== c1 && (
																					<div className={styles.sourcePrice}>
																						{DataModel.Currency.GetSymbol(item.i1.o, c1)}
																					</div>
																				)}
																			</div>
																			<div className={`${styles.tdPrice} ${filter.appId !== Apps.CsGo ? styles.tablePrice : styles.tablePriceWithL}`}
                                        										onDoubleClick={() => 
																					handleDoubleClickPriceSecond(i)
																				}>
																				{DataModel.Currency.GetSymbol(item.i2.p)}
																				{DataModel.Currency.Current !== c2 && (
																					<div className={styles.sourcePrice}>
																						{DataModel.Currency.GetSymbol(item.i2.o, c2)}
																					</div>
																				)}
																			</div>
																			<div
																				className={`${styles.tdProfit} ${filter.appId !== Apps.CsGo ? styles.tableProfit : styles.tableProfitWithL} ${
																					item.p > 0 ? styles.profitPositive : styles.profitNegative
																				}`}
																			>
																				{item.p} %
																			</div>
																			<div className={`${styles.tdCount} ${filter.appId !== Apps.CsGo ? styles.tableCount : styles.tableCountWithL}`}>
																				<a
																					className={`${styles.flex} flex`}
																					href={Extensions.GetItemLink(name, filter.appId, filter.service1)}
																					rel='noreferrer nofollow'
																					target='_blank'
																				>
																					<div className={styles.count}>
																						<span>
																							{item.i1.c !== -1 ? (
																								(() => {
																									item.i1.c ??= 0
																									const max = item.i1.m

																									if (max > 0) {
																										return (
																											<span>
																												{item.i1.c}/{max}
																											</span>
																										)
																									} else {
																										return <span>{item.i1.c}</span>
																									}
																								})()
																							) : (
																								<span>1+</span>
																							)}
																						</span>
																					</div>
																					<div>
																						<img
																							src={`Resources/ServicesIcons/${EServices[
																								filter.service1
																							].toLowerCase()}.png`}
																							alt='service'
																						/>
																					</div>
																				</a>
																			</div>
																			<div className={`${styles.tdCount} ${filter.appId !== Apps.CsGo ? styles.tableCount : styles.tableCountWithL}`}>
																				<a
																					className={`${styles.flex} flex`}
																					href={Extensions.GetItemLink(name, filter.appId, filter.service2)}
																					rel='noreferrer nofollow'
																					target='_blank'
																				>
																					<div
																						className={`${styles.count} ${
																							item.i2.s > EItemStatus.Tradable ? styles.warning : ''
																						}`}
																					>
																						<span>
																							{item.i2.c !== -1 ? (
																								(() => {
																									item.i2.c ??= 0
																									const max = item.i2.m

																									if (max > 0) {
																										return (
																											<span>
																												{item.i2.c}/{max}
																											</span>
																										)
																									} else {
																										return <span>{item.i2.c}</span>
																									}
																								})()
																							) : (
																								<span>1+</span>
																							)}
																						</span>
																					</div>
																					<div>
																						<img
																							src={`Resources/ServicesIcons/${EServices[
																								filter.service2
																							].toLowerCase()}.png`}
																						/>
																					</div>
																				</a>
																			</div>
																		</div>
																	)
																}}
															/>
														</div>
													)
												}}
											</AutoSizer>
										)
									}}
								</WindowScroller>
							</div>
						)
					})()}
					{!tableLoading && next && (
						<div className='text-center'>
							<div
								className={`${styles.btnMoreData} btn-hov`}
								onClick={async () => {
									page += 1
									setTableLoading(true)
									await getItemsChunk()
									setTableLoading(false)
								}}
							>
								{lang.ProfilePage_Transactions_More}
							</div>
						</div>
					)}
					<div style={{ height: 30 }}></div>
					{/* <LargePromoTradeit /> */}
				</div>
			)}
			{tableLoading && (
				<div className={`${styles.tableLoading} ${styles.flex} flex`}>
					<LoadingSpinner />
				</div>
			)}
			<PriceCheck appId={filter.appId}/>
			<FilterCheck onClose={async needRefresh => {
				if (needRefresh) {
					await updateItems()
				}
			}}/>
		</div>
	)
}

export default TablePage
