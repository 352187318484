import { Sub } from './../types/subscription/sub'
import { CurrencyModel } from './../types/currency/currency'
import { ServicesFee } from './../types/table/requestData'
import { UserInfo } from './../types/user/userInfo'
import { User } from './../types/user/user'
import { config } from './../config'
import { EServices } from '../types/tableHelper'
import LangController from './language/langController'
import Extensions from './extensions'
import TimeExtensions from './timeExtensions'
import TableExtensions from './pageModels/table/tableExtensions'
import Currency from './currency'

export default class DataModel {
	static AppName: string = 'vvTable'
	static CurrentPage: string
	static TelegramBot: string = config.TelegramBot
	static VkAPI: number = config.VkAPI
	static BaseUrl: string
	static User: User
	static UserInfo: UserInfo
	static Fees: Object
	static Subs: Sub[]
	static Currency: Currency = new Currency()
	static Version: string
	static PromoTradeitImg = Extensions.GetRandomInt(4) + 1
	static PromoCsMoneyRandomImage = Math.floor(Math.random() * 2)
	
	static GetDefaultFees(): Object {
		const fees = new Object()

		for (const app of TableExtensions.AppIds()) {
			const def = new Object()

			def[EServices.Steam] = { fee: 13.03, bonus: 0 }
			def[EServices.TM] = { fee: 5, bonus: 0 }
			def[EServices.Shadowpay] = { fee: 5, bonus: 0 }
			def[EServices.Waxpeer] = { fee: 5.9, bonus: 0 }
			def[EServices.Bitskins] = { fee: 10, bonus: 0 }
			def[EServices.DMarket] = { fee: 5, bonus: 0 }
			def[EServices.Buff] = { fee: 2.5, bonus: 0 }
			def[EServices.BuffMarket] = { fee: 2.5, bonus: 0 }
			def[EServices.C5game] = { fee: 0, bonus: 0 }
			def[EServices.Igxe] = { fee: 2.5, bonus: 0 }
			def[EServices.Skinbaron] = { fee: 15, bonus: 0 }
			def[EServices.Skinport] = { fee: 12, bonus: 0 }
			def[EServices.CSDealsMarket] = { fee: 2, bonus: 0 }
			def[EServices.VMarket] = { fee: 10, bonus: 0 }
			def[EServices.SwapMarket] = { fee: 5, bonus: 0 }
			def[EServices.BitskinsP2P] = { fee: 4.99, bonus: 0 }
			def[EServices.CsFloat] = { fee: 2, bonus: 0 }
			def[EServices.TMNew] = { fee: 5, bonus: 0 }
			def[EServices.CsMoneyMarket] = { fee: 5, bonus: 0 }
			def[EServices.SkinoutMarket] = { fee: 2, bonus: 0 }
			def[EServices.WhiteMarket] = { fee: 6, bonus: 0 }
			def[EServices.DMarketP2P] = { fee: 5, bonus: 0 }

			def[EServices.CSMoney] = { fee: 7, bonus: 0 }
			def[EServices.CSDeals] = { fee: 2, bonus: 0 }
			def[EServices.CSTrade] = { fee: 5, bonus: 0 }
			def[EServices.Tradeit] = { fee: 10, bonus: 3 }
			def[EServices.LF] = { fee: 5, bonus: 0 }
			def[EServices.Swap] = { fee: 8, bonus: 3 }
			def[EServices.TradeSkinsFast] = { fee: 7, bonus: 0 }
			def[EServices.SkinsMonkey] = { fee: 7, bonus: 0 }
			def[EServices.SkinSwap] = { fee: 9.91, bonus: 0 }
			def[EServices.ITrade] = { fee: 3, bonus: 0 }

			def[EServices.CsFail] = { fee: 0, bonus: 0 }
			def[EServices.LisSkins] = { fee: 0, bonus: 0 }
			def[EServices.AvanMarket] = { fee: 0, bonus: 0 }
			def[EServices.RapidSkins] = {fee: 0, bonus: 0}
			def[EServices.SkinThunder] = {fee: 3, bonus: 0}
			def[EServices.SkinBid] = {fee: 8, bonus: 0}
			def[EServices.Mannco] = {fee: 5, bonus: 0}
      		def[EServices.HaloSkins] = {fee: 3, bonus: 0}
	  		def[EServices.SkinFlow] = {fee: 4, bonus: 0}
			def[EServices.SkinDeck] = {fee: 3, bonus: 0}
			def[EServices.CSGO500] = { fee: 0, bonus: 0 }		

			fees[app] = def
		}

		return fees
	}

	static GetStringTimeByUnixSec(sec: number): string {
		const max = 253402300799 //DateTimeOffset.MaxValue.ToUnixTimeSeconds()
		if (sec > max) sec = max
		sec -= Date.now() / 1000

		if (sec < 0) return ''

		if (sec < 60) {
			return `${TimeExtensions.GetTotalSeconds(sec)} ${LangController.GetCurrent().Value.TablePage_Seconds}`
		} else if (sec < 3600) {
			return `${TimeExtensions.GetTotalMinutes(sec)} ${LangController.GetCurrent().Value.TablePage_Minutes}`
		} else if (sec < 86400) {
			return `${TimeExtensions.GetTotalHours(sec)} ${LangController.GetCurrent().Value.TablePage_Hours}`
		} else {
			return `${TimeExtensions.GetTotalDays(sec)} ${LangController.GetCurrent().Value.TablePage_Days}`
		}
	}

	static LoadAllFees(user: User) {
		if (this.Fees) return

		var defaultFees = this.GetDefaultFees()

		var key = `${user.steamID}_fees`

		const feesJson = localStorage.getItem(key)

		if (feesJson) {
			try {
				this.Fees = JSON.parse(feesJson)
			} catch (err) {
				console.error(err)

				this.Fees = defaultFees
			}
		} else {
			this.Fees = defaultFees
		}

		for (var app of TableExtensions.AppIds()) {
			if (!this.Fees.hasOwnProperty(app)) this.Fees[app] = new Object()

			const enumValues = Extensions.GetEnumValues(EServices)

			for (var service of enumValues) {
				if (!this.Fees[app].hasOwnProperty(service)) {
					this.Fees[app][service] = defaultFees[app].hasOwnProperty(service) ? defaultFees[app][service] : new ServicesFee()
				}
			}
		}
	}

	static saveAllFees(user: User) {
		const json = JSON.stringify(this.Fees)

		localStorage.setItem(`${user.steamID}_fees`, json)
	}
}
