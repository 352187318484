import { api } from './../../api/api'
import { ProfileWithJson } from './../../types/table/profile'
import { ESalesPeriod, EServices, TableHelper } from './../../types/tableHelper'
import { ESubscription, User } from './../../types/user/user'
import { ETableActionTypes, TableAction } from './../../types/redux/table'
import { Filter, FilterSales } from '../../types/table/filter'
import { Dispatch } from 'react'
import { defaultTypes } from '../../models/tableFilterModel'
import Extensions from '../../models/extensions'
import { RootState } from '../reducers'
import { Apps } from '../../apps'

export const setTableFilter = (value: Filter, saveFilter: boolean, saveTypes: boolean = false) => {
	return async (dispatch: Dispatch<TableAction>, getState: () => RootState) => {
		const state = getState()
		const profileId = state.user.activeProfile

		if (saveFilter) saveTableFilter(value, profileId)
		if (saveTypes) saveTableTypes(value, profileId)

		dispatch({ type: ETableActionTypes.SET_TABLE_FILTER, payload: value })
	}
}

export const saveTableFilter = async (filter: Filter, profileId: number) => {
	const saveObj: Filter = { ...filter, searchName: undefined, allTypes: undefined }

	const json = JSON.stringify(saveObj)

	await api.post('table/profile/update', { id: profileId, filtersJson: json } as ProfileWithJson)
}

export const saveTableTypes = async (filter: Filter, profileId: number) => {
	const dataToSave = Filter.GetTypesObject(filter)
	const json = JSON.stringify(dataToSave)

	await api.post('table/profile/update', { id: profileId, typesJson: json } as ProfileWithJson)
}

export const loadTableFilter = (user: User, profile: ProfileWithJson, freeServices: Set<EServices>, disabledServices: Set<EServices>) => {
	return (dispatch: Dispatch<TableAction>) => {
		try {
			const filterJson = profile?.filtersJson
			const typesJson = profile?.typesJson

			let filter: Filter

			if (filterJson) {
				filter = JSON.parse(filterJson)

				filter.allTypes = defaultTypes()
			}

			if (!filterJson || !filter) {
				filter = new Filter()
				filter.allTypes = defaultTypes()
			}

			// TODO: need to delete after 26 Feb. 2024
			// TODO: NEW NO DELETE!
			if (filter.filterSales) {
				const periods = Extensions.GetEnumValues(ESalesPeriod)

				periods.forEach(period => {
					if (filter?.filterSales?.hasOwnProperty(period))
					{
						const services = Extensions.GetEnumValues(EServices)
						{
							services.forEach(service => {
								if (!Object.hasOwn(filter.filterSales[period], service) ||
									typeof filter.filterSales[period][service] != "number")
									return

								filter.filterSales[period][service] = { minSales: filter.filterSales[period][service], maxSales: 0 }
							})
						}
					}
				})
			} 

			filter.appId ??= Apps.CsGo
			filter.countMin1 ??= 1
			filter.countMin2 ??= 0
			filter.countMax1 ??= 0
			filter.countMax2 ??= 0
			filter.service1 ??= 0
			filter.service2 ??= 0
			filter.minSales ??= 0
			filter.direction ??= 0
			filter.order ??= 0
			filter.priceMax1 ??= 0
			filter.priceMax2 ??= 0
			filter.priceMin1 ??= 0
			filter.priceMin2 ??= 0
			filter.priceType1 ??= 0
			filter.priceType2 ??= 0
			filter.profitMax ??= 0
			filter.profitMin ??= 0
			filter.salesPeriod ??= 0
			filter.salesService ??= 0
			filter.liquidityMin ??= 0
			filter.liquidityMax ??= 100
			
			filter.searchName = ''

			if (typesJson) {
				const types = JSON.parse(typesJson)
				if (types) {
					for (const app of filter.allTypes) {
						for (const name of app[1].Types) {
							for (const type of name[1]) {
								const typeValue = types[app[0]][name[0]][type[0]]
								
								filter.allTypes.get(app[0]).Types.get(name[0]).get(type[0]).SetTypeValue(typeValue)
							}
						}
					}
				}
			}

			const sub = user.access

			if (sub != ESubscription.ProSubscription) {
				filter.inInventory = false;
			}
			
			const defService: EServices = Extensions.GetEnumValues(EServices).find(
				x => (TableHelper.CheckServiceBySubscription(x, sub) || freeServices.has(x)) && !disabledServices.has(x)
			)
			// Check for service is no access or disabled
			if (
				!TableHelper.CheckServiceExists(filter.service1) || 
				(!TableHelper.CheckServiceBySubscription(filter.service1, sub) && !freeServices.has(filter.service1)) ||
				disabledServices.has(filter.service1) ||
				(TableHelper.CheckServiceForSecret(filter.service1) && !user.secretAccess)
			) {
				filter.service1 = defService
			}
			
			if (
				!TableHelper.CheckServiceExists(filter.service2) || 
				(!TableHelper.CheckServiceBySubscription(filter.service2, sub) && !freeServices.has(filter.service2)) ||
				disabledServices.has(filter.service2) ||
				(TableHelper.CheckServiceForSecret(filter.service2) && !user.secretAccess)
			) {
				filter.service2 = defService
			}

			// Check for sales period is no access
			if (
				!TableHelper.CheckForSalesBySubscription(filter.salesService, sub, filter.salesPeriod, freeServices) &&
				TableHelper.CheckForSalesBySubscription(filter.salesService, sub, 0, freeServices)
			) {
				filter.salesPeriod = 0
			}

			// Check for sales service is no access or disabled
			if (
				(!TableHelper.CheckForSalesBySubscription(filter.salesService, sub, filter.salesPeriod, freeServices) &&
					!freeServices.has(filter.salesService)) ||
				disabledServices.has(filter.salesService)
			) {
				const defServiceSales = Extensions.GetEnumValues(EServices).find(
					x =>
						TableHelper.CheckForSalesAvailable(x, 0) &&
						(TableHelper.CheckForSalesBySubscription(x, sub, 0, freeServices) || disabledServices.has(x)) &&
						!disabledServices.has(x)
				)

				filter.salesService = defServiceSales
				filter.salesPeriod = 0
			}

			dispatch({ type: ETableActionTypes.LOAD_TABLE_FILTER_FROM_PROFILE, payload: filter })
		} catch (error) {
			console.log(error)
			const filter: Filter = new Filter()

			filter.appId = Apps.CsGo
			filter.countMin1 = 1
			filter.countMin2 = 0
			filter.countMax1 = 0
			filter.countMax2 = 0
			filter.allTypes = defaultTypes()
			filter.service1 =
				filter.service2 =
				filter.minSales =
				filter.direction =
				filter.order =
				filter.priceMax1 =
				filter.priceMax2 =
				filter.priceMin1 =
				filter.priceMin2 =
				filter.priceType1 =
				filter.priceType2 =
				filter.profitMax =
				filter.profitMin =
				filter.salesPeriod =
				filter.salesService =
					0

			filter.searchName = ''

			dispatch({ type: ETableActionTypes.LOAD_TABLE_FILTER_FROM_PROFILE, payload: filter })
		}
	}
}
