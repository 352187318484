import { Apps } from '../apps'
import HttpHelper from './../types/httpHelper'
import { EServices } from './../types/tableHelper'
import LangController from './language/langController'

export default class Extensions {
	static GetItemLink(name: string, appId: number, service: EServices): string {
		const lang: string = LangController.Current === 0 ? 'ru' : 'en'
		const game: string = this.GetGameByAppId(appId)

		let nameEncoded = HttpHelper.GetEncodeText(name)

		const followTo = `item-link/${lang}/${appId}/${service}/${nameEncoded}`

		switch (service) {
			case EServices.Steam:
				return `https://steamcommunity.com/market/listings/${appId}/${nameEncoded}`
			case EServices.TM:
				name = name.replace('&', '')
				return `${this.GetTmSiteByAppId(appId)}?s=price&search=${name}&sd=asc`
			case EServices.Buff:
				return followTo
			case EServices.BuffMarket:
				return followTo
			case EServices.Bitskins:
				return `https://bitskins.com/market/${game}?ref_alias=vvtable&search={"order":[{"field":\"price\",\"order\":\"ASC\"}],\"where\":{\"skin_name\":\"${nameEncoded}\"}}`
			case EServices.C5game:
				return followTo
			case EServices.CSDealsMarket:
				return `https://cs.deals/new?name=${name}&sort=price&sort_desc=0`
			case EServices.DMarket:
				name = name.replace('★', '').replace('™', '').replace('&', '')
				return `https://dmarket.com/${LangController.Current != 1 ? `${lang}/` : ''}ingame-items/item-list/${this.GetDmarketLinkStrByAppId(
					appId
				)}?title=${name}`
			case EServices.Igxe:
				return followTo
			case EServices.Shadowpay:
				return `https://shadowpay.com/${lang}?game=${game}&search=${nameEncoded}&sort_column=price&sort_dir=asc`
			case EServices.Skinbaron:
				return `https://skinbaron.de/${lang}/${this.GetGameByAppId(appId)}?str=${nameEncoded}&sort=BP`
			case EServices.Skinport:
				return followTo
			case EServices.Waxpeer:
				nameEncoded = nameEncoded.replace('★', '').replace('™', '')
				return `https://waxpeer.com/?sort=asc&order=price&game=${this.GetGameByAppId(appId)}&search=${nameEncoded}`
			// Exchangers
			case EServices.CSDeals:
				return `https://cs.deals/trade-skins`
			case EServices.CSMoney:
				let gameC = appId === Apps.CsGo ? 'csgo' : 'dota'
				return `https://cs.money/${gameC}/trade/?utm_source=mediabuy&utm_medium=vvtable&utm_campaign=vvtable&utm_content=link&search=${nameEncoded}&sort=price&order=asc`
			case EServices.CSTrade:
				return appId === Apps.CsGo ? `https://cs.trade/trade?market_name=${name}` : "https://cs.trade/#trader";
			case EServices.LF:
				return `https://loot.farm/`
			case EServices.Swap:
				return `https://swap.gg/?r=7iA6cHAfwFzjYbKifsmXq`
			case EServices.Tradeit:
				return `https://tradeit.gg/${lang === 'ru' ? 'ru/' : ''}${game}/trade?search=${name}&aff=vvtable`
			case EServices.TradeSkinsFast:
				return `https://tradeskinsfast.com/`
			case EServices.VMarket:
				return `https://vmarket.gg/${appId}/${nameEncoded}`
			case EServices.SkinsMonkey:
				return `https://skinsmonkey.com/${lang === 'ru' ? 'ru/' : ''}trade?appId=${appId}&sort=price-asc&q=${name}`
			case EServices.SkinSwap:
				return `https://skinswap.com/trade/?search=${name}&ref=vvtable`
			case EServices.CsFail:
				return `https://5cs.fail/${lang}/`
			case EServices.LisSkins:
				return `https://lis-skins.ru/market/${game}/?query=${nameEncoded}&rf=219131`
			case EServices.SwapMarket:
				return followTo
			case EServices.BitskinsP2P:
				return followTo
			case EServices.CsFloat:
				return `https://csfloat.com/`
			case EServices.TMNew:
				return `https://market.csgo.com/${lang}/${nameEncoded}`
			case EServices.CsMoneyMarket:
				return `https://cs.money/${lang}/market/?utm_source=mediabuy&utm_medium=vvtable&utm_campaign=vvtable&utm_content=link&search=${nameEncoded}&sort=price&order=asc`
			case EServices.ITrade:
				return `https://itrade.gg/trade/${Extensions.GetGameByAppId(appId)}?search=${name}&ref=vvtable`
			case EServices.SkinoutMarket:
				return `https://skinout.gg/${lang}/market?search=${nameEncoded}&sort=price_asc`
			case EServices.WhiteMarket:
				return `https://white.market/item?appId=${appId}&nameHash=${nameEncoded}`
			case EServices.AvanMarket:
				let gameA = appId === Apps.CsGo ? "cs" : appId === Apps.Dota2 ? "dota" : "rust"
				return `https://avan.market/${lang}/market/${gameA}?name=${name}`
			case EServices.RapidSkins:
				return `https://rapidskins.com/a/vvtable`
			case EServices.SkinThunder:
				return `https://skinthunder.com/category?query=${name}&ref=EACMPUCA`
			case EServices.SkinBid:
				return `https://skinbid.com/market?sort=price%23asc&sellType=fixed_price&take=120&skip=0&search=${name}`
			case EServices.Mannco:
				let gameB = appId === Apps.CsGo ? "cs2" : appId === Apps.Dota2 ? "dota" : appId === Apps.Rust ? "rust" : "tf2"
				return `https://mannco.store/${gameB}?search=${name}&ref=yzg5mty`
      		case EServices.HaloSkins:
				return `https://www.haloskins.com/market?sort=1&keyword=${name}`
			case EServices.SkinFlow:
				return `https://skinflow.gg/trade?search=${name}`
			case EServices.SkinDeck:
				return `https://skindeck.com/sell?tab=withdraw`
			case EServices.CSGO500:
				const gameS = appId === Apps.CsGo ? "cs2" : "rust"

				return `https://csgo500.com/p2p/${gameS}/withdraw`
			default:
				throw Error('Unknown service')
		}
	}

	static GetReferralLink(service: EServices): string {
		const lang: string = LangController.Current === 0 ? 'ru' : 'en'

		switch (service) {
			case EServices.Steam:
				return 'https://steamcommunity.com/market'
			case EServices.TM:
				return 'https://market.csgo.com'
			case EServices.Buff:
				return 'https://buff.163.com'
			case EServices.BuffMarket:
				return 'https://buff.market/r/U1093452240'
			case EServices.Bitskins:
				return 'https://bitskins.com/?ref_alias=vvtable'
			case EServices.C5game:
				return 'https://www.c5game.com'
			case EServices.CSDealsMarket:
				return 'https://cs.deals/new'
			case EServices.DMarket:
				return `https://dmarket.com/${LangController.Current != 1 ? `${lang}/` : ''}`
			case EServices.Igxe:
				return 'https://www.igxe.cn'
			case EServices.Shadowpay:
				return `https://shadowpay.com/${lang}`
			case EServices.Skinbaron:
				return `https://skinbaron.de/${lang}`
			case EServices.Skinport:
				return 'https://skinport.com'
			case EServices.Waxpeer:
				return 'https://waxpeer.com/r/vvtable'
			// Exchangers
			case EServices.CSDeals:
				return `https://cs.deals/trade-skins`
			case EServices.CSMoney:
				return `https://cs.money/${lang}/trade`
			case EServices.CSTrade:
				return `https://cs.trade/#trader`
			case EServices.LF:
				return `https://loot.farm/`
			case EServices.Swap:
				return `https://swap.gg/?r=7iA6cHAfwFzjYbKifsmXq`
			case EServices.Tradeit:
				return `https://tradeit.gg/${lang === 'ru' ? 'ru/' : ''}csgo/trade?aff=vvtable`
			case EServices.TradeSkinsFast:
				return `https://tradeskinsfast.com`
			case EServices.VMarket:
				return 'https://vmarket.gg'
			case EServices.SkinsMonkey:
				return `https://skinsmonkey.com/${lang === 'ru' ? 'ru/' : ''}trade`
			case EServices.SkinSwap:
				return `https://skinswap.com/trade/?ref=vvtable`
			case EServices.CsFail:
				return `https://5cs.fail/${lang}/`
			case EServices.LisSkins:
				return 'https://lis-skins.ru/?rf=219131'
			case EServices.SwapMarket:
				return 'https://market.swap.gg'
			case EServices.DMarketP2P:
				return 'https://dmarketp2p.com'
			case EServices.BitskinsP2P:
				return 'https://p2p.bitskins.com/?ref_alias=vvtable'
			case EServices.CsFloat:
				return 'https://csfloat.com/'
			case EServices.TMNew:
				return 'https://market.csgo.com/'
			case EServices.CsMoneyMarket:
				return `https://cs.money/${lang}/market/buy/`
			case EServices.ITrade:
				return 'https://itrade.gg?ref=vvtable'
			case EServices.SkinoutMarket:
				return 'https://skinout.gg/'
			case EServices.WhiteMarket:
				return 'https://white.market/invite/1f0158a7a81f0797'
			case EServices.AvanMarket:
				return 'https://avan.market/ru/market'
			case EServices.RapidSkins:
				return 'https://rapidskins.com/a/vvtable'
			case EServices.SkinThunder:
				return 'https://skinthunder.com/?ref=EACMPUCA'
			case EServices.SkinBid:
				return `https://skinbid.com/market`
			case EServices.Mannco:
				return `https://mannco.store/?ref=yzg5mty`
      		case EServices.HaloSkins:
				return `https://www.haloskins.com/`
			case EServices.SkinFlow:
				return `https://skinflow.gg/`
			case EServices.SkinDeck:
				return `https://skindeck.com/sell?tab=withdraw`
			case EServices.CSGO500:		
				return `https://csgo500.com`
			default:
				throw Error('Unknown service')
		}
	}

	static GetProfileAvatarLink(imageHash: string, fullSize: boolean = false): string {
		return `https://steamcdn-a.akamaihd.net/steamcommunity/public/images/avatars/3d/${imageHash}_${fullSize ? 'full' : 'medium'}.jpg`
	}
	static GetItemImageLink(imageHash: string, size: number = 128): string {
		return `https://community.akamai.steamstatic.com/economy/image/${imageHash}/${size}fx${size}f`
	}

	static GetGameByAppId(appId: number): string {
		switch (appId) {
			case Apps.CsGo:
				return 'csgo'
			case Apps.Dota2:
				return 'dota2'
			case Apps.Tf2:
				return 'tf2'
			case Apps.Rust:
				return 'rust'
			default:
				throw Error('Unknown game')
		}
	}
	static GetGamePrettyByAppId(appId: number): string {
		switch (appId) {
			case Apps.CsGo:
				return 'CS2'
			case Apps.Dota2:
				return 'Dota 2'
			case Apps.Tf2:
				return 'TF2'
			case Apps.Rust:
				return 'Rust'
			default:
				throw Error('Unknown game')
		}
	}

	static GetTmSiteByAppId(appId: number): string {
		switch (appId) {
			case Apps.CsGo:
				return 'https://market-old.csgo.com/'
			case Apps.Dota2:
				return 'https://market.dota2.net/'
			case Apps.Tf2:
				return 'https://tf2.tm/'
			case Apps.Rust:
				return 'https://rust.tm/'
			default:
				throw Error('Unknown game')
		}
	}
	static GetDmarketLinkStrByAppId(appId: number): string {
		switch (appId) {
			case Apps.CsGo:
				return 'csgo-skins'
			case Apps.Dota2:
				return 'dota2-skins'
			case Apps.Tf2:
				return 'tf2-skins'
			case Apps.Rust:
				return 'rust-skins'
			default:
				throw Error('Unknown game')
		}
	}

	static NickLetters(): number {
		return 24
	}
	static GetShortNickname(nickname: string): string {
		return nickname?.length > this.NickLetters() ? nickname.substring(0, this.NickLetters()) + '...' : nickname
	}

	static Round(num: number) {
		return Math.round((num + Number.EPSILON) * 100) / 100
	}

	static Floor(num: number) {
		return Math.floor((num + Number.EPSILON) * 100) / 100
	}

	static Ceil(num: number) {
		return Math.ceil((num + Number.EPSILON) * 100) / 100
	}

	static GetEnumNames(enumType): string[] {
		const arr: string[] = []

		for (let enumMember in enumType) {
			const isValueProperty = parseInt(enumMember, 10) >= 0
			if (isValueProperty) {
				arr.push(enumType[enumMember])
			}
		}

		return arr
	}

	static GetEnumValues(enumType): number[] {
		const arr: number[] = []

		for (let enumMember in enumType) {
			const isValueProperty = parseInt(enumMember, 10) >= 0
			if (isValueProperty) {
				arr.push(parseInt(enumMember, 10))
			}
		}

		return arr
	}

	static ZeroPad(num: number, numZeros: number) {
		var an = Math.abs(num)
		var digitCount = 1 + Math.floor(Math.log(an) / Math.LN10)
		if (digitCount >= numZeros) {
			return num
		}
		var zeroString = Math.pow(10, numZeros - digitCount)
			.toString()
			.substring(1)
		return num < 0 ? '-' + zeroString + an : zeroString + an
	}

	static GetRandomInt(max: number) {
		return Math.floor(Math.random() * max)
	}

	static StringFormat(text: string, ...params: any[]): string {
		return text.replace(/{(\d+)}/g, function(match, number) { 
			return typeof params[number] != 'undefined'
				? params[number]
				: match
			;
		});
	}
}
