import React, { FC, ReactNode, useEffect, useState } from 'react'
import { useActions } from '../../hooks/useAction'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import Extensions from '../../models/extensions'
import { EPriceType, EServices, TableHelper } from '../../types/tableHelper'
import Dropdown from '../Elements/Dropdown'
import InputFloat from '../Elements/InputFloat'
import styles from './css/SelectService.module.css'
import InputInt from '../Elements/InputInt'

export interface SelectServiceProps {
	onChanged?: (value: number) => void
	onNeedClear: () => void
	onNeedReset: () => void
	isSecond: boolean
}

const SelectService: FC<SelectServiceProps> = props => {
	const { isSecond, onChanged, onNeedClear, onNeedReset } = props

	const {
		user,
		feed: { freeServices: _freeServices, disabledServices: _disabledServices },
	} = useTypedSelector(x => x.user)
	const freeServices = new Set(_freeServices)
	const disabledServices = new Set(_disabledServices)
	const { filter, loaded: filterLoaded } = useTypedSelector(x => x.table)
	const {
		lang: { Value: lang },
	} = useTypedSelector(x => x.lang)

	const { setTableFilter } = useActions()

	if (!filter) return

	const changeService = (service: EServices) => {
		if (!isSecond) {
			if (filter.service1 === service) return
			if (filter.service1 !== filter.service2) onNeedClear?.call(this)
			filter.service1 = service
			filter.priceType1 = EPriceType.Normal
		} else {
			if (filter.service2 === service) return
			if (filter.service1 !== filter.service2) onNeedClear?.call(this)
			filter.service2 = service
			filter.priceType2 = EPriceType.Normal
		}

		setTableFilter(filter, true)

		onNeedReset?.call(this)
	}

	const changePriceTypeStatus = (priceType: EPriceType) => {
		if (!isSecond) {
			if (filter.priceType1 === priceType) return
			filter.priceType1 = priceType
		} else {
			if (filter.priceType2 === priceType) return
			filter.priceType2 = priceType
		}

		setTableFilter(filter, true)

		onChanged?.call(this)
	}

	const getPriceTypeStr = (priceType: EPriceType): string => {
		return (
			{
				[EPriceType.Normal]: lang.TablePage_PriceTypeNormal,
				[EPriceType.Deposit]: lang.TablePage_PriceTypeDeposit,
				[EPriceType.Order]: lang.TablePage_PriceTypeOrder,
				[EPriceType.Average]: lang.TablePage_PriceTypeAverage,
				[EPriceType.NoHold]: lang.TablePage_PriceTypeNoHold,
			}[priceType] || 'Normal'
		)	
	}

	let printedExch = false
	let printedRoulette = false
	
	return (
		<div>
			<Dropdown title={`${!isSecond ? lang.TablePage_FirstService : lang.TablePage_SecondService}:`} style={{ marginLeft: 30, marginRight: 10 }}>
				<div className={`${styles.title} title`}>
					<div className={styles.serviceLogo}>
						<img
							src={`Resources/ServicesIcons/${
								!isSecond ? EServices[filter.service1].toLowerCase() : EServices[filter.service2].toLowerCase()
							}.png`}
							alt='service'
						/>
					</div>
					{TableHelper.GetServiceName(!isSecond ? filter.service1 : filter.service2)}
				</div>
				<div className='menu'>
					<div className='menu-header no-close'>{lang.TablePage_Markets}</div>
					{Extensions.GetEnumValues(EServices)
						.filter(x => TableHelper.CheckServiceIsMarket(x))
						.concat(Extensions.GetEnumValues(EServices).filter(x => !TableHelper.CheckServiceIsMarket(x) && !TableHelper.CheckServiceIsRoulette(x)))
						.concat(Extensions.GetEnumValues(EServices).filter(x => TableHelper.CheckServiceIsRoulette(x)))
						.map((service, i) => {
							if (!TableHelper.CheckServiceByAppId(service, filter.appId)) return
							if (disabledServices.has(service)) return
							if (TableHelper.CheckServiceForSecret(service) && !user.secretAccess) return

							const noAccess = !(TableHelper.CheckServiceBySubscription(service, user.access) || freeServices.has(service))
							const isMarket = TableHelper.CheckServiceIsMarket(service)
							const isRoulette = !isMarket && TableHelper.CheckServiceIsRoulette(service)
							const isExch = !isMarket && !isRoulette
							const icon = `Resources/ServicesIcons/${EServices[service].toLowerCase()}.png`
							const name = TableHelper.GetServiceName(service)
							const currService = !isSecond ? filter.service1 : filter.service2

							let titleJsx: ReactNode

							if (!printedExch && isExch) {
								printedExch = true
								titleJsx = <div className='menu-header no-close'>{lang.TablePage_Exchangers}</div>
							}

							if (!printedRoulette && isRoulette) {
								printedRoulette = true
								titleJsx = <div className='menu-header no-close'>{lang.TablePage_Roulette}</div>
							}

							return (
								<React.Fragment key={i}>
									{titleJsx}
									{noAccess ? (
										<div className={`${styles.menuItem} ${styles.noClose} ${styles.noAccess} menu-item no-close`}>
											<div className={`${styles.serviceLogo} ${styles.noClose} no-close`}>
												<img className={`${styles.noClose} no-close`} src={icon} alt='icon' />
											</div>
											<div className={`${styles.noClose} d-inline-block no-close`}>
												{name}
												<div className={`${styles.noAccessPre} ${styles.noClose} d-inline-block no-close`}>Pro</div>
											</div>
										</div>
									) : (
										<div className={`${styles.menuItem} menu-item`} onClick={() => changeService(service)}>
											{currService === service && <i className='fa fa-check' aria-hidden='true'></i>}
											<div className={styles.serviceLogo}>
												<img src={icon} alt='icon' />
											</div>
											{name}
										</div>
									)}
								</React.Fragment>
							)
						})}
				</div>
			</Dropdown>
			<Dropdown title={`${lang.TablePage_PriceTypeTitle}:`}>
				<div className={`${styles.title} title`}>{getPriceTypeStr(!isSecond ? filter.priceType1 : filter.priceType2)}</div>
				<div className='menu'>
					{Extensions.GetEnumValues(EPriceType).map((priceType, i) => {
						const currService = !isSecond ? filter.service1 : filter.service2
						if (!TableHelper.CheckServicePriceType(currService, priceType)) return
						const name = getPriceTypeStr(priceType)
						
						return (
							<div className={`${styles.menuItem} menu-item`} onClick={() => changePriceTypeStatus(priceType)} key={i}>
								{(!isSecond && filter.priceType1 === priceType) || (isSecond && filter.priceType2 === priceType) ? (
									<i className='fa fa-check' aria-hidden='true'></i>
								) : null}{' '}
								{name}
							</div>
						)
					})}
				</div>
			</Dropdown>
			<div className={`${styles.settingsFilter} flex`}>
				<div className={styles.inputLabel}>
					<div>{lang.TablePage_MinPrice}: </div>

					{!isSecond ? (
						<InputFloat
							className={`${styles.inputStyle} ${styles.price} input-style text-left`}
							defaultValue={filter.priceMin1}
							onValueChange={value => {
								filter.priceMin1 = value
								setTableFilter(filter, true)
							}}
						/>
					) : (
						<InputFloat
							className={`${styles.inputStyle} ${styles.price} input-style text-left`}
							defaultValue={filter.priceMin2}
							onValueChange={value => {
								filter.priceMin2 = value
								setTableFilter(filter, true)
							}}
						/>
					)}
				</div>
				<div className={styles.inputLabel}>
					<div>{lang.TablePage_MaxPrice}: </div>
					{!isSecond ? (
						<InputFloat
							className={`${styles.inputStyle} ${styles.price} input-style text-left`}
							defaultValue={filter.priceMax1}
							onValueChange={value => {
								filter.priceMax1 = value
								setTableFilter(filter, true)
							}}
						/>
					) : (
						<InputFloat
							className={`${styles.inputStyle} ${styles.price} input-style text-left`}
							defaultValue={filter.priceMax2}
							onValueChange={value => {
								filter.priceMax2 = value
								setTableFilter(filter, true)
							}}
						/>
					)}
				</div>
				<div className={styles.inputLabel}>
					<div>{lang.TablePage_MinCount}:</div>
					{!isSecond ? (
						<InputFloat
							className={`${styles.inputStyle} ${styles.price} input-style text-left`}
							defaultValue={filter.countMin1}
							onValueChange={value => {
								filter.countMin1 = value
								setTableFilter(filter, true)
							}}
						/>
					) : (
						<InputFloat
							className={`${styles.inputStyle} ${styles.price} input-style text-left`}
							defaultValue={filter.countMin2}
							onValueChange={value => {
								filter.countMin2 = value
								setTableFilter(filter, true)
							}}
						/>
					)}
				</div>
				<div className={styles.inputLabel}>
					<div>{lang.TablePage_MaxCount}:</div>
					{!isSecond ? (
						<InputFloat
							className={`${styles.inputStyle} ${styles.price} input-style text-left`}
							defaultValue={filter.countMax1}
							onValueChange={value => {
								filter.countMax1 = value
								setTableFilter(filter, true)
							}}
						/>
					) : (
						<InputFloat
							className={`${styles.inputStyle} ${styles.price} input-style text-left`}
							defaultValue={filter.countMax2}
							onValueChange={value => {
								filter.countMax2 = value
								setTableFilter(filter, true)
							}}
						/>
					)}
				</div>
				{ !isSecond && TableHelper.CheckServicePriceType(filter.service1, EPriceType.NoHold) ? (
					<div className={styles.inputLabel}>
						<div>{lang.TablePage_MaxHoldHours}:</div>
						<InputInt
						className={`${styles.inputStyle} ${styles.price} input-style text-left`}
						defaultValue={filter.maxHoldHours ?? 0}
						onValueChange={value => {
							filter.maxHoldHours = value
							setTableFilter(filter, true)
						}}/>
					</div>
					) : null }
			</div>
		</div>
	)
}

export default SelectService
