import DataModel from '../dataModel'
import Extensions from '../extensions'
import { ILanguage } from './language'

export class RussianLang implements ILanguage {
	HeaderMenu = 'Меню'
	MainPageWelcome = `Добро пожаловать на ${DataModel.AppName}`

	// menu
	MenuHome = 'Главная'
	MenuProfile = 'Профиль'
	MenuTable = 'Таблица'
	MenuInventory = 'Инвентарь'
	MenuSubscribe = 'Подписка'
	MenuBalance = 'Баланс'
	MenuSupport = 'Поддержка'
	MenuAbout = 'О нас'
	MenuSettings = 'Настройки'
	MenuLogin = 'Войти'
	MenuLogout = 'Выйти'
	MenuLanguage = 'Язык'
	MenuBuy = 'Пополнение'

	// profile page
	ProfilePage_Sub = 'Подписка'
	ProfilePage_Balance = 'Баланс'
	ProfilePage_Deposit = 'Пополнить'
	ProfilePage_SubPause = 'Приостановить'
	ProfilePage_SubPauseAlreadyPaused = 'Приостановлено'
	ProfilePage_PromoCodeTitle = 'Промокод'
	ProfilePage_ActivatePromoCode = 'Активировать'
	ProfilePage_ReferralCodeTitle = 'Реферальный код'
	ProfilePage_ConfirmReferralCode = 'Подтвердить'
	ProfilePage_YourCode = 'Ваш код'
	ProfilePage_ReferralsCount = 'Рефералов'
	ProfilePage_ReferralsCountWithSub = 'Купили подписку'
	ProfilePage_KeysAvailable = 'Ключей к выводу'
	ProfilePage_CopyLink = 'Скопировать ссылку'

	ProfilePage_Transactions_Title = 'Список операций'
	ProfilePage_Transactions_Empty = 'Список операций пуст'
	ProfilePage_Transactions_More = 'Больше'

	ProfilePageNotification_PromoNotFound = 'Промокод не найден'
	ProfilePageNotification_PromoNoUses = 'Промокод иcчерпан'
	ProfilePageNotification_PromoAlreadyUsed = 'Вы уже использовали данный промокод'
	ProfilePageNotification_PromoAlreadyUsedGroup = 'Вы уже использовали данную группу промокодов'
	ProfilePageNotification_PromoHasSub = 'У вас уже есть активная подписка'
	ProfilePageNotification_AlreadyStopped = 'Подписка уже приостановлена'
	ProfilePageNotification_PauseNotAvailableByDelay = 'Вы уже пользовались паузой, следующая будет доступна '
	ProfilePageNotification_PauseNotAvailableBySub = 'Невозможно активировать паузу без подписки'

	ProfilePageNotification_ReferralAlreadyRegistered = 'Вы уже стали рефералом'
	ProfilePageNotification_ReferralReferralCodeNotFound = 'Реферальный код не найден'
	ProfilePageNotification_ReferralSelfCode = 'Вы не можете стать своим рефералом'

	// sub page

	SubPage_SubInformation = 'Информация'

	SubPageNotification_NoBalance = 'Недостаточно средств!'
	SubPageNotification_SubIsBetter = 'Ваша подписка выше!'
	SubPageNotification_SubPaused = 'Ваша подписка приостановлена!'

	// support page
	SupportPage_Title = 'Сообщите нам о проблеме'
	SupportPage_Name = 'Как вас зовут?'
	SupportPage_Info = 'Как с вами связаться?'
	SupportPage_Problem = 'Опишите проблему:'
	SupportPage_Images = 'Добавить скриншоты'
	SupportPage_ButtonSend = 'Отправить'
	SupportNotify_Success = 'Успешно!'
	SupportNotify_ErrorEmptyFields = 'Заполните все поля!'

	// balance page
	BalancePage_Crypto = 'Криптовалюта'
	BalancePage_DepositBtn = 'Пополнить'
	BalancePage_RefillBtn = 'Пополнить'

	// table page
	TablePage_ProfileList = 'Профиль'
	TablePage_ProfileListAdd = 'Добавить'
	TablePage_ProfileStartValue = 'Профиль'
	TablePage_Game = 'Игра'
	TablePage_Currency = 'Валюта'
	TablePage_Filters = 'Фильтры'
	TablePage_Clear = 'Очистить'
	TablePage_Reset = 'Сбросить'
	TablePage_Lists = 'Списки'
	TablePage_ResetFilters = 'Сбросить фильтры'
	TablePage_SalesService = 'Сервис продаж'
	TablePage_SalesPeriod = 'Период продаж'
	TablePage_SalesMin = 'Мин. продаж'
	TablePage_SalesDay = '3 Дня'
	TablePage_SalesWeek = 'Неделя'
	TablePage_SalesMonth = 'Месяц'
	TablePage_SalesMonth3 = '3 Месяца'
	TablePage_FirstService = 'Первый сервис'
	TablePage_SecondService = 'Второй сервис'
	TablePage_Markets = 'Площадки'
	TablePage_Exchangers = 'Обменники'
	TablePage_Roulette = 'Рулетки'
	TablePage_Time = 'Время (мин)'
	TablePage_MinPrice = 'Мин цена'
	TablePage_MaxPrice = 'Макс цена'
	TablePage_MinCount = 'Мин шт'
	TablePage_MaxCount = 'Макс шт'
	TablePage_MinProfit = 'Профит мин %'
	TablePage_MaxProfit = 'Профит макс %'
	TablePage_PriceTypeTitle = 'Тип цены'
	TablePage_ServiceTitle = 'Сервис'
	TablePage_PriceTypeNormal = 'Обычная'
	TablePage_PriceTypeDeposit = 'Депозит'
	TablePage_PriceTypeOrder = 'Автобай'
	TablePage_PriceTypeAverage = 'Средняя'
	TablePage_PriceTypeNoHold = 'Без холда'
	TablePage_ButtonUpdate = 'Обновить'
	TablePage_ButtonCheckPrice = 'Проверить цену'
	TablePage_Table_SkinName = 'Название'
	TablePage_Table_Tools = 'Инструменты'
	TablePage_Table_Search = 'Поиск'
	TablePage_Table_Sales = 'Продажи'
	TablePage_Table_Price = 'Цена'
	TablePage_Table_Update = 'Обн'
	TablePage_Table_Profit = 'Прибыль'
	TablePage_Table_Count = 'Кол-во'
	TablePage_Seconds = 'сек'
	TablePage_Minutes = 'мин'
	TablePage_Hours = 'ч'
	TablePage_Days = 'д'
	TablePage_CheckPrice_Title = 'Просмотр цен предмета на всех сервисах'
	TablePage_CheckPrice_ItemName = 'Название предмета'
	TablePage_CheckPrice_ButtonFind = 'Найти'
	TablePage_Message_NoItems = 'Нет предметов. Попробуйте изменить фильтры.'
	TablePage_SearchNotification_NotFound = 'Ничего не найдено!'
	TablePage_LastUpdate_Title = 'Последнее обновление'
	TablePage_LastUpdate_Never = 'Никогда'
	TablePage_LastUpdate_Now = 'Сейчас'
	TablePage_LastUpdate_Ago = 'назад'
	TablePage_Sales_Total = 'Всего'
	TablePage_Sales_Max = 'Макс'
	TablePage_Sales_Min = 'Мин'
	TablePage_Sales_Avg = 'Сред'
	TablePage_Sales_Corridor = 'Коридор'
	TablePage_Sales_Last = 'Последние'
	TablePage_Lists_Profiles = 'Профили'
	TablePage_Lists_Favorites = 'Избранное'
	TablePage_Lists_BlackList = 'Чёрный список'
	TablePage_Lists_Currency = 'Валюта'
	TablePage_Title_Copy = 'Копировать'
	TablePage_Title_AddToFavorites = 'Добавить в избранное'
	TablePage_Title_RemoveFromFavorites = 'Убрать из избранного'
	TablePage_Title_AddToBlacklist = 'Добавить в чёрный список'
	TablePage_Title_AddToBlacklistTmp = 'Убрать до изменения цены'
	TablePage_Title_PriceCheck = 'Просмотр цен на всех сервисах'
	TablePage_Toast_ItemsWasUpdated = 'Предметы были обновлены'
	TablePage_FilterCheck_Title = 'Фильтры продаж'
	TablePage_AutoReset = 'Авто обновл.'
	TablePage_Yes = 'Да'
	TablePage_No = 'Нет'
	TablePage_MaxHoldHours = 'Макс холд, ч.'
	TablePage_Sound = 'Звук'
	TablePage_InInventory = 'Из инвентаря'
	TablePage_ChangePrice = 'Изменить цену'
	TablePage_ConfirmChangePrice = 'Изменить'
	TablePage_Notification_TooManyRequests = 'Слишком много запросов!'
	TablePage_LiquidityMin = 'Ликв. мин %'
	TablePage_LiquidityMax = 'Ликв. макс %'
	TablePage_Liquidity = 'Ликв.'

	// notification default
	Notification_Error = 'Ошибка'
	Notification_Success = 'Успешно'
	Notification_CopySuccess = 'Скопировано'
	Notification_InventoryClosedError = 'Ошибка, ваш инвентарь скрыт'

	// confirmations

	Confirmation_DefaultText = 'Подтвердите действие'
	Confirmation_Logout = 'Вы действительно хотите выйти?'
	Confirmation_BuySub = 'Подтвердите покупку'
	Confirmation_DoDeposit = 'Пополнить баланс?'
	Confirmation_PauseSubscribeTitle = 'Приостановить подписку на две недели'
	Confirmation_PauseSubscribe = 'Вы уверены что хотите приостановить вашу подписку на две недели? (Восстановить подписку вручную невозможно)'
	Confirmation_ResetFilters = 'Вы уверены, что хотите сбросить все фильтры?'

	// login form

	LoginForm_Text = 'Для перехода на эту страницу вам необходимо авторизоваться.'
	LoginForm_Back = 'Назад'

	// user settings page

	UserSettingsPage_Reset = 'Сбросить'
	UserSettingsPage_UserInfoTitle = 'Подключения'
	UserSettingsPage_SettingsTitle = 'Комиссии и бонусы'
	UserSettingsPage_Commission = 'Комиссия'
	UserSettingsPage_Bonus = 'Бонус'
	UserSettingsPage_GetTradeOfferLink = 'Получить'
	UserSettingsPage_SaveTradeOfferLink = 'Сохранить'

	UserSettingsPage_Label_TradeOfferLink = 'Трейд ссылка'
	UserSettingsPage_Label_Vk = 'Вк'
	UserSettingsPage_Label_Telegram = 'Телеграм'

	UserSettingsPageNotification_IncorrectTradeOfferLink = 'Некорректная ссылка на обмен'
	UserSettingsPageNotification_TokenAlreadyUsed = 'Данная ссылка на обмен уже используется'
	UserSettingsPageNotification_IncorrectData = 'Некорректные данные'
	UserSettingsPageNotification_AccountAlreadyUsed = 'Эта учетная запись была привязана другим пользователем'

	UserSettingsPage_ResetCommissions(app: number) {
		return `Вы действительно хотите сбросить все комиссии для ${Extensions.GetGamePrettyByAppId(app)}?`
	}

	// Texts

	Text_DepositKeys(count: number) {
		return `Пополнение: x ${count}`
	}

	// Footer

	Footer_AllRightsReserved = 'Все права защищены'
	Footer_TermsOfUse = 'Условия использования'

	// Preview page

	PreviewPage_BtnStart = 'Начать'
	PreviewPage_Box_Title = 'Новый уровень трейдинга'
	PreviewPage_Box_SubTitle = 'Steam трейдинг лучше с vvTable!'
	PreviewPage_TotalItems_Title = 'Предметов отслеживается'
	PreviewPage_Adv_Title_1 = 'Выбор сервисов для трейдинга на любой вкус'
	PreviewPage_Adv_Description_1 = 'Мы предоставляем пользователям более 20 сервисов для торговли предметами из 4 игр.'
	PreviewPage_Adv_Title_2 = 'Самое быстрое время обновления предметов'
	PreviewPage_Adv_Description_2 = 'Мы используем передовые технологии парсинга, поэтому цены на предметы обновляются примерно за 10 минут.'
	PreviewPage_Adv_Title_3 = 'Удобный способ пополнения баланса'
	PreviewPage_Adv_Description_3 =
		'Мы учли предпочтения сообщества трейдеров, поэтому мы выбрали предмет «Mann Co. Supply Crate Key» как способ пополнения баланса.'
	PreviewPage_TrustContent_Title = 'О нас говорят'
	PreviewPage_Banner_Title = 'Начните путь настоящего трейдера'
	PreviewPage_Banner_Description = 'Авторизуйтесь на vvTable и окунитесь в мир Steam трейдинга, зарабатывая на обмене скинов'

	// new year
	NewYear_Title = 'Новый год'
	NewYear_Enable = 'Включить'
	NewYear_Disable = 'Отключить'

	// connection
	MainConnection_ConnectionLostTitle = `Соединение потеряно`
	MainConnection_ConnectionLostBody = `Попытка подключения...` 
	MainConnection_ConnectionLostBodySec = `Попытка подключения через {0} сек.`
	MainConnection_ConnectionSuccessHeader = `Соеденение востановленно`
	MainConnection_ConnectionSuccessBody = `Успешное подключение!`

	//SelectSalesPeriod
	SelectSalesPeriod_FilterServices = 'Фильтр'

	// ESort Inventory
	ESort_AscendingPrice = 'Цена по возр'
	ESort_DescendingPrice = 'Цена по убыв'
	ESort_AscendingLiquidity = 'Ликвидность возр'
	ESort_DescendingLiquidity = 'Ликвидность убыв'

	// InventoryPage
	InventoryPage_SortedPriceTitle = 'Сортировка'
	InventoryPage_InventoryEmptyText = 'Инвентарь пуст'
	InventoryPage_InventoryCountText = 'Количество предметов'
	InventoryPage_InventoryPriceText = 'Сумма инвентаря'
	InventoryPage_InventoryTotalPrice = 'Общая сумма инвентаря в сервисах'
	InventoryPage_InfoItem = 'Информация о предмете'
	InventoryPage_CopyName = 'Копировать название'
	InventoryPage_ServiceItemLink = 'Перейти к предмету'
	InventoryPage_SteamLinkInventory = 'Перейти в инвентарь'
	InventoryPage_UpdateInventory = 'Обновить'
	InventoryPage_MarketableText = 'Продаваемый'
}

