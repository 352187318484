import { ItemType, EType, ETypeValue } from './../types/tableHelper'
import { ItemTypeController } from '../types/tableHelper'
import { Apps } from '../apps';

// export const defaultTypes
export const defaultTypes = () => {
	const _defaultTypes = new Map<number, ItemTypeController>()

	_defaultTypes.set(
		0,
		new ItemTypeController(
			new Map<string, Map<EType, ItemType>>([
				[
					'',
					new Map<EType, ItemType>([
						[EType.OverstockAndUnavailable, new ItemType('Overstock', [ETypeValue.None, ETypeValue.Without], ETypeValue.Without)],
						[EType.Favorites, new ItemType('Favorites', [ETypeValue.None, ETypeValue.Only])],
					]),
				],
			])
		)
	)

	_defaultTypes.set(
		Apps.CsGo,
		new ItemTypeController(
			new Map<string, Map<EType, ItemType>>([
				[
					'',
					new Map<EType, ItemType>([
						[EType.Csgo_Stattrak, new ItemType('StatTrak™')],
						[EType.Csgo_Souvenir, new ItemType('Souvenir', null, ETypeValue.Without)],
						[EType.Csgo_Knife, new ItemType('★ Knife', null, ETypeValue.None, EType.Csgo_Stattrak)],
						[EType.Csgo_Glove, new ItemType('★ Gloves')],
						[EType.Csgo_Agent, new ItemType('Agent')],
						[EType.Csgo_Sticker, new ItemType('Sticker')],
						[EType.Csgo_Patch, new ItemType('Patch')],
						[EType.Csgo_Graffiti, new ItemType('Graffiti', null, ETypeValue.Without)],
						[EType.Csgo_Container, new ItemType('Container')],
						[EType.Csgo_Tools, new ItemType('Tools')],
						[EType.Csgo_Pins, new ItemType('Pins')],
						[EType.Csgo_Passes, new ItemType('Passes')],
						[EType.Csgo_Gifts, new ItemType('Gifts')],
						[EType.Csgo_MusicKits, new ItemType('Music Kits', null, ETypeValue.None, EType.Csgo_Stattrak)],
					]),
				],
			])
		)
	)

	_defaultTypes.set(
		Apps.Dota2,
		new ItemTypeController(
			new Map<string, Map<EType, ItemType>>([
				[
					'Rarity',
					new Map<EType, ItemType>([
						[EType.Dota_1_Common, new ItemType('Common', null, ETypeValue.Without)],
						[EType.Dota_1_Uncommon, new ItemType('Uncommon')],
						[EType.Dota_1_Rare, new ItemType('Rare')],
						[EType.Dota_1_Mythical, new ItemType('Mythical')],
						[EType.Dota_1_Immortal, new ItemType('Immortal')],
						[EType.Dota_1_Legendary, new ItemType('Legendary')],
						[EType.Dota_1_Arcana, new ItemType('Arcana')],
						[EType.Dota_1_Ancient, new ItemType('Ancient')],
					]),
				],
				[
					'Quality',
					new Map<EType, ItemType>([
						[EType.Dota_2_Inscribed, new ItemType('Inscribed')],
						[EType.Dota_2_Auspicious, new ItemType('Auspicious', null, ETypeValue.Without)],
						[EType.Dota_2_Heroic, new ItemType('Heroic', null, ETypeValue.Without)],
						[EType.Dota_2_Genuine, new ItemType('Genuine', null, ETypeValue.Without)],
						[EType.Dota_2_Autographed, new ItemType('Autographed', null, ETypeValue.Without)],
						[EType.Dota_2_Frozen, new ItemType('Frozen', null, ETypeValue.Without)],
						[EType.Dota_2_Cursed, new ItemType('Cursed', null, ETypeValue.Without)],
						[EType.Dota_2_Infused, new ItemType('Infused', null, ETypeValue.Without)],
						[EType.Dota_2_Unusual, new ItemType('Unusual', null, ETypeValue.Without)],
						[EType.Dota_2_Exalted, new ItemType('Exalted', null, ETypeValue.Without)],
						[EType.Dota_2_Elder, new ItemType('Elder', null, ETypeValue.Without)],
						[EType.Dota_2_Legacy, new ItemType('Legacy', null, ETypeValue.Without)],
						[EType.Dota_2_Ascendant, new ItemType('Ascendant', null, ETypeValue.Without)],
						[EType.Dota_2_Favored, new ItemType('Favored', null, ETypeValue.Without)],
					]),
				],
			])
		)
	)

	_defaultTypes.set(
		Apps.Tf2,
		new ItemTypeController(
			new Map<string, Map<EType, ItemType>>([
				[
					'',
					new Map<EType, ItemType>([
						[EType.Tf_Tool, new ItemType('Tool')],
						[EType.Tf_Gift, new ItemType('Gift')],
						[EType.Tf_SupplyCrate, new ItemType('Supply Crate')],
						[EType.Tf_Recipe, new ItemType('Recipe')],
					]),
				],
			])
		)
	)

	_defaultTypes.set(
		Apps.Rust,
		new ItemTypeController(
			new Map<string, Map<EType, ItemType>>([
				[
					'',
					new Map<EType, ItemType>([
						[EType.Rust_Weapon, new ItemType('Weapon')],
						[EType.Rust_Misc, new ItemType('Misc')],
						[EType.Rust_ClothingArmor, new ItemType('Clothing and Armor')],
						[EType.Rust_Resource, new ItemType('Resource')],
					]),
				],
			])
		)
	)

	return _defaultTypes
};

// import { ItemType, EType, ETypeValue } from './../types/tableHelper'
// import { ItemTypeController } from '../types/tableHelper'

// const _defaultTypes: Object = {}

// _defaultTypes[0] = new ItemTypeController({
// 	['']: {
// 		[EType.OverstockAndUnavailable]: new ItemType('Overstock', [ETypeValue.None, ETypeValue.Without], ETypeValue.Without),
// 		[EType.Favorites]: new ItemType('Favorites', [ETypeValue.None, ETypeValue.Only]),
// 	},
// })

// _defaultTypes[Apps.CsGo] = new ItemTypeController({
// 	['']: {
// 		[EType.Csgo_Stattrak]: new ItemType('StatTrak™', null, ETypeValue.None, EType.Csgo_Knife),
// 		[EType.Csgo_Souvenir]: new ItemType('Souvenir', null, ETypeValue.Without),
// 		[EType.Csgo_Knife]: new ItemType('★ Knife'),
// 		[EType.Csgo_Glove]: new ItemType('★ Gloves'),
// 		[EType.Csgo_Agent]: new ItemType('Agent'),
// 		[EType.Csgo_Sticker]: new ItemType('Sticker'),
// 		[EType.Csgo_Patch]: new ItemType('Patch'),
// 		[EType.Csgo_Graffiti]: new ItemType('Graffiti', null, ETypeValue.Without),
// 		[EType.Csgo_Container]: new ItemType('Container'),
// 	},
// })

// _defaultTypes[Apps.Dota2] = new ItemTypeController({
// 	['Rarity']: {
// 		[EType.Dota_1_Common]: new ItemType('Common', null, ETypeValue.Without),
// 		[EType.Dota_1_Uncommon]: new ItemType('Uncommon'),
// 		[EType.Dota_1_Rare]: new ItemType('Rare'),
// 		[EType.Dota_1_Mythical]: new ItemType('Mythical'),
// 		[EType.Dota_1_Immortal]: new ItemType('Immortal'),
// 		[EType.Dota_1_Legendary]: new ItemType('Legendary'),
// 		[EType.Dota_1_Arcana]: new ItemType('Arcana'),
// 		[EType.Dota_1_Ancient]: new ItemType('Ancient'),
// 	},
// 	['Quality']: {
// 		[EType.Dota_2_Inscribed]: new ItemType('Inscribed'),
// 		[EType.Dota_2_Auspicious]: new ItemType('Auspicious', null, ETypeValue.Without),
// 		[EType.Dota_2_Heroic]: new ItemType('Heroic', null, ETypeValue.Without),
// 		[EType.Dota_2_Genuine]: new ItemType('Genuine', null, ETypeValue.Without),
// 		[EType.Dota_2_Autographed]: new ItemType('Autographed', null, ETypeValue.Without),
// 		[EType.Dota_2_Frozen]: new ItemType('Frozen', null, ETypeValue.Without),
// 		[EType.Dota_2_Cursed]: new ItemType('Cursed', null, ETypeValue.Without),
// 		[EType.Dota_2_Infused]: new ItemType('Infused', null, ETypeValue.Without),
// 		[EType.Dota_2_Unusual]: new ItemType('Unusual', null, ETypeValue.Without),
// 		[EType.Dota_2_Exalted]: new ItemType('Exalted', null, ETypeValue.Without),
// 		[EType.Dota_2_Elder]: new ItemType('Elder', null, ETypeValue.Without),
// 		[EType.Dota_2_Legacy]: new ItemType('Legacy', null, ETypeValue.Without),
// 		[EType.Dota_2_Ascendant]: new ItemType('Ascendant', null, ETypeValue.Without),
// 		[EType.Dota_2_Favored]: new ItemType('Favored', null, ETypeValue.Without),
// 	},
// })

// _defaultTypes[Apps.Tf2] = new ItemTypeController({
// 	['']: {
// 		[EType.Tf_Tool]: new ItemType('Tool'),
// 		[EType.Tf_Gift]: new ItemType('Gift'),
// 		[EType.Tf_SupplyCrate]: new ItemType('Supply Crate'),
// 		[EType.Tf_Recipe]: new ItemType('Recipe'),
// 	},
// })

// _defaultTypes[Apps.Rust] = new ItemTypeController({
// 	['']: {
// 		[EType.Rust_Weapon]: new ItemType('Weapon'),
// 		[EType.Rust_Misc]: new ItemType('Misc'),
// 		[EType.Rust_ClothingArmor]: new ItemType('Clothing and Armor'),
// 		[EType.Rust_Resource]: new ItemType('Resource'),
// 	},
// })

// export const defaultTypes = _defaultTypes
